
import React, { Component, useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { API_BASE_URL } from "../../Data/content";


export default function Slider2Animation() {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      const doc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(`<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>combine</title>
    <script src="https://cdn.tailwindcss.com"></script>
<link rel="stylesheet" href="style1.css">
<link rel="stylesheet" href="style2.css">
<style>
:root {
    --cards: 4;
    --cardHeight: 87vh;
    --cardTopPadding: 1.5em;
    --cardMargin: 4vw;
}
main {
    /* margin: 24rem 0; */
    z-index: 99;
}
.s1-title{
    position: sticky;
    top: 6vh;
    font-size: 2rem;
    font-weight: 600;
    color: hsl(39, 100%, 38%);
    text-align: center;
    width: 100%;

}

.s1-card-container {
    display: flex;
    height: 45vh;
    position: sticky;
    top: 12rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem; /* done by Ankit */
}

.s1-card {
    /* border: solid 2px saddlebrown; */

    position: relative;
    width: 50%;
    background: white;
    border-radius: 36px;
    /* padding: 2rem; */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.s1-card-content {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.s1-content-section {
    flex: 1;
    padding: 2rem;
}
.s1-top-icon{
    width: 3rem;
    height: 3rem;
}
.s1-content-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.s1-content-text {
    color: #666;
    line-height: 1.6;
}

.s1-image-section {
    flex: 1;
    overflow: hidden;
    border-radius: 0 36px 36px 0;
}

.s1-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Card positioning classes */
.s1-second-card {
    bottom: -50px;
    /* bottom: calc(-50px - 0px); */
}

.s1-third-card {
    bottom: -125px;
    /* bottom: calc(-125px - 40px); */
} 

.s1-fourth-card {
    bottom: -200px;
    /* bottom: calc(-200px - 10px); */
}

/* borderGlowingEffect effect */
.s1-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 36px;
    background: linear-gradient(45deg, #ff000020, #ffd70020, #ffffff, #ffd70020, #ff000020);
    background-size: 400% 400%;
    z-index: -1;
    animation: borderGlowingEffect 8s linear infinite;
}

@keyframes borderGlowingEffect {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
@media screen and ((max-width: 300px) or (max-width: 800px)) {

    .s1-card-container{
        margin-bottom: 9rem;
        margin-left: -8rem;
        margin-right: -8rem;

    }
    .s1-content-title {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #333;
    }
    .s1-content-text {
        color: #666;
        line-height: 1.2;
        font-size: 0.8rem;
    }
}

/* Responsive design */
@media screen and ((max-width: 768px) or (max-width: 1080px)) {
    /* .s1-card {
        width: 90%;
    } */

    .s1-card-content {
        flex-direction: row;
    }

    .s1-second-card {
        bottom: -50px;
    }

    .s1-third-card {
        bottom: -150px;
    }

    .s1-fourth-card {
        bottom: -200px;
    }
    
}
.gsap-s1-container {
    width: 100%;
    margin: 0 auto;
}

#gsap-s1-cards {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(var(--cards), var(--cardHeight));
    gap: var(--cardMargin);
    padding-bottom: calc(var(--cards) * var(--cardTopPadding));
    margin-bottom: var(--cardMargin);
}

#gsap-s1-card1 {
    --index: 1;
}

#gsap-s1-card2 {
    --index: 2;
}

#gsap-s1-card3 {
    --index: 3;
}

#gsap-s1-card4 {
    --index: 4;
}

.gsap-s1-card {
    position: sticky;
    top: 0;
    /* padding-top: calc(var(--index) * var(--cardTopPadding)); */
}

/* #gsap-s1-card1 .gsap-s1-card-body {
    background-color: #52b2cf;
}

#gsap-s1-card2 .gsap-s1-card-body {

    background-color: #e5a36f;
}

#gsap-s1-card3 .gsap-s1-card-body {
    background-color: #9cadce;
}

#gsap-s1-card4 .gsap-s1-card-body {
    background-color: #d4afb9;
} */

.gsap-s1-card-body {
    /* box-sizing: border-box; */
    /* padding: 30px; */
    /* border-radius: 50px; */
    /* box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3); */
    height: var(--cardHeight);
    /* display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s; */
}

h2 {
    font-size: 2.5em;
}



/* -----------------------s1 slider css end------------------------------------------ */
.s2-graph-dashes {
height: 0.125rem;
width: 0.25rem;
border-radius: 999rem;
z-index: 20;
background-color: rgb(75, 85, 99, 0.3);
}

.s2-image-container {
position: relative;
bottom: 1.5rem;
z-index: -20;
display: flex;
height: 400px;
width: 400px;
align-items: center;
justify-content: center;
background-image: url('../assets/hourglass.jpeg');
background-size: contain;
background-repeat: no-repeat;
}

.s2-changeBlockGradient {
top: -2rem;
height: 20rem;
width: 12rem;
opacity: 0.5;
filter: blur(4rem);
position: absolute;

z-index: -10;

}

.s2-block2-section {
position: absolute;

top: 6rem;

display: flex;

width: 100%;

justify-content: center;

border-radius: 1rem;



}

.s2-FadeAnimation {

height: 0.5rem;

width: 2rem;

border-radius: 0.25rem;

background-color: rgb(229, 231, 235);
}

.s2-SpringGrowAnimation {
content: "";
}

.s2-block2-green-bar {
width: 100%;
background: linear-gradient(to bottom, rgba(74, 222, 128, 0.6), #10b981);
border-radius: 0.5rem;
}

.s2-graph-bars-container {
display: flex;

width: 100%;

flex-direction: column;

align-items: center;

gap: 0.5rem;
}

.s2-SpringGrowAnimation {
width: 100%;
border-radius: 0.5rem;
}

.s2-graph-bars-container:nth-child(1) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(251, 191, 36, 0.6), #84cc16);

height: 6rem;
}

.s2-graph-bars-container:nth-child(2) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(168, 85, 247, 0.6), #a855f7, #f9a8d4);

height: 6rem;
}

.s2-graph-bars-container:nth-child(3) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(251, 191, 36, 0.6), #ec4899);

height: 8rem;
}

.s2-graph-bars-container:nth-child(4) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(251, 191, 36, 0.6), #84cc16);

height: 6rem;
}

.s2-graph-bars-container:nth-child(5) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(251, 191, 36, 0.6), #f87171, #f9a8d4);

height: 7rem;
}

.s2-graph-bars-container:nth-child(6) .s2-SpringGrowAnimation {

background: linear-gradient(to bottom, rgba(168, 85, 247, 0.6), #a855f7, #f9a8d4);

height: 9rem;
}

.s2-div {
position: relative;
margin-top: 10rem;
}

@media screen and ((max-width: 300px) or (max-width: 800px)) {
.s2-image-container {
width: 300px;
}

.s2-div {
position: relative;
margin-top: 16rem;
}
}
 
</style>
</head>

<body>
    
    <div class="gsap-s1-container">
        <h1 class="s1-title" >Features that transcend</h1>
        <ul id="gsap-s1-cards">
            <li class="gsap-s1-card" id="gsap-s1-card1">
                <div class="gsap-s1-card-body">
                    <div class="s1-card-container">
                        <div class="s1-card">
                            <div class="s1-card-content">
                                <div class="s1-content-section">
                                    <img class="s1-top-icon" src="images/ai_2.png" alt="">
                                    <h2 class="s1-content-title">Advanced AI-Powered Matchmaking</h2>
                                    <p class="s1-content-text">Find your perfect match effortlessly with our AI-powered search—tailored to your preferences and just a click away!.</p>
                                </div>
                                <div class="s1-image-section">
                                    <img src="https://framerusercontent.com/images/5cy3aBC0zohwsVKKU2qTF2YdBI.png" alt="Slider 1 Card 1" class="s1-card-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="gsap-s1-card" id="gsap-s1-card2">
                <div class="gsap-s1-card-body">
                    <div class="s1-card-container">
                        <div class="s1-card s1-second-card">
                            <div class="s1-card-content">
                                <div class="s1-content-section">
                                    <img class="s1-top-icon" src="images/yagya_2.png" alt="">
                                    <h2 class="s1-content-title">Rooted in Vedic Values</h2>
                                    <p class="s1-content-text">Guided by Vedic values, our expert matchmakers ensure a meaningful, tradition-aligned match.</p>
                                </div>
                                <div class="s1-image-section">
                                    <img src="https://framerusercontent.com/images/PjfDPMpsgzjWwtrTffIY7XVUq0.png" alt="Slider 1 Card 2" class="s1-card-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="gsap-s1-card" id="gsap-s1-card3">
                <div class="gsap-s1-card-body">
                    <div class="s1-card-container">
                        <div class="s1-card s1-third-card">
                            <div class="s1-card-content">
                                <div class="s1-content-section">
                                    <img class="s1-top-icon" src="images/counsel_2.png" alt="">
                                    <h2 class="s1-content-title">Comprehensive Education and Counselling</h2>
                                    <p class="s1-content-text">Vedic Marriage focuses on nurturing lasting relationships through expert pre-marital and marital education and counseling.</p>
                                </div>
                                <div class="s1-image-section">
                                    <img src="https://framerusercontent.com/images/TFlyldncmgwJR9OnpfaI50YbNYU.png?scale-down-to=512" alt="Slider 1 Card 3" class="s1-card-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="gsap-s1-card" id="gsap-s1-card4">
                <div class="gsap-s1-card-body">
                    <div class="s1-card-container">
                        <div class="s1-card s1-fourth-card">
                            <div class="s1-card-content">
                                <div class="s1-content-section">
                                    <img class="s1-top-icon" src="images/privacy_2.png" alt="">
                                    <h2 class="s1-content-title">Privacy and Trust</h2>
                                    <p class="s1-content-text">Your privacy is our priority — control your visibility, block members, and report misuse for a secure and personalized journey to your Vedic soulmate.</p>
                                </div>
                                <div class="s1-image-section">
                                    <img src="https://framerusercontent.com/images/AB27iEVvNXrsfDH6p2pfc1U0U.png" alt="Slider 1 Card 3" class="s1-card-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
   
    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/ScrollTrigger.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/ScrollToPlugin.min.js"></script>


<script>
//gsap.to()... infinity and beyond!

//To learn more about using GreenSock's bonus plugins for free on CodePen visit: https://gsap.com/trial
gsap.registerPlugin(ScrollTrigger);

let card = gsap.utils.toArray(".gsap-s1-card");

function initCards() {
    animation.clear();
    cardHeight = cards[0].offsetHeight;
    cards.forEach((card, index) => {
        if (index === 0) {
            gsap.set(card, {
                y: 0,
                backgroundColor: "#FDFAFF",
                opacity: 1,
                duration: 0.1
            });
            animation.to(
                card,
                {
                    y: -40 * cards.length,
                    duration: 0.1,
                    opacity: 0
                },
                0
            );
        } else if (index === 1) {
            gsap.set(card, {
                y: cardHeight,
                backgroundColor: "transparent",
                opacity: 1,
                duration: 1
            });
            animation.to(
                card,
                {
                    y: -40 * cards.length,
                    opacity: 0,
                    duration: 0.4
                },
                0
            );
        } else {
            gsap.set(card, {
                y: cardHeight * 2,
                backgroundColor: "transparent",
                opacity: 0,
                duration: 0.1
            });
            animation.to(
                card,
                {
                    y: 0,
                    duration: 4,
                    opacity: 1
                },
                0
            );
        }
    });
}

ScrollTrigger.create({
    trigger: ".section",
    start: "top",
    pin: ".section",
   
    scrub: true,
    animation,
    pinSpacing: true,
    markers: true,
    invalidateOnRefresh: true,
    onLeave: (e) => console.log(e),
    ease: "Power2.in",
    // snap: {
    //   snapTo:1/(cards.length - 1),
    //   duration:{min:0.1, max:0.6}

    // },
    toggleActions: "restart none restart none"
});
 gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const iconWrappers = document.querySelectorAll('.s2-icon-wrapper');
const iconsSelector = document.querySelectorAll('.s2-arc-icon');
const imagesSelector = document.querySelectorAll('.s2-section-reveal-anm');
const radius = 350;
const totalIcons = iconsSelector.length;

// Initialize images
gsap.set(imagesSelector, { opacity: 0 });
gsap.set(imagesSelector[0], { opacity: 1 });
// Set initial opacity for all icons
gsap.set(iconsSelector, { opacity: 0 });

gsap.set(iconsSelector[0], { opacity: 1, scale: 1.25 });
gsap.set(iconsSelector[1], { opacity: 0.35, scale: 0.9 });
gsap.set(iconsSelector[2], { opacity: 0.35, scale: 0.9 });

// Function to calculate s2-arc-icon positions based on progress
function setIconPositions(progress) {
    console.log(progress);
    iconWrappers.forEach((wrapper, index) => {
        const startAngledeg = -90;
        const startAngle = startAngledeg * (Math.PI / 180);
        // const arcLength = (3.14/180)*130;
        const arcLength = 1.20;
        const angle = startAngle + (index / (totalIcons - 1)) * arcLength - progress * arcLength;
        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;
        gsap.set(wrapper, {
            x: x,
            y: y,
            rotation: 0,
        });
    });
}

// Function to calculate target progress for centering an icon
function getTargetProgressForIndex(index) {
    return index / (totalIcons - 1);
}

// Define background colors
const bgGrads = [
    '#ffa1ad', // orange
    '#ffa1ad', // green
    '#ffa1ad', // fuchsia
    '#ffa1ad', // green
    '#ffa1ad', // amber
    '#ffa1ad', // violet
];

// Function to update visible image and s2-arc-icon opacities
let currentVisibleIndex = -1;
function updateVisibleImage(closestIndex) {

    if (closestIndex !== currentVisibleIndex) {
        currentVisibleIndex = closestIndex; // Keep track of the currently active index

        imagesSelector.forEach((section, idx) => {
            const elemGradientDiv = section.querySelector('.s2-changeBlockGradient');
            const addSpringGrowVar = section.querySelectorAll('.s2-SpringGrowAnimation');
            const addFadeVar = section.querySelectorAll('.s2-FadeAnimation');
            if (idx !== closestIndex) {
                // Reset non-active sections
                gsap.to(section, {
                    opacity: 0,
                    // y: 40, // Slide down blocks when becoming inactive
                    y: 20, // Slight movement instead of large jumps
                    duration: 0.3,
                    ease: "power2.out",
                });
                gsap.to(elemGradientDiv, {
                    backgroundColor: 'transparent',
                    duration: 0.3,
                });
            } else {
                // Animate the active section with slide-up
                gsap.to(
                    section,
                    {
                        opacity: 1,
                        y: 0, // Keep the position stable for smoother transition
                        duration: 0.5, // Slightly longer duration for smooth appearance
                        ease: "power2.out",
                    });


                gsap.to(elemGradientDiv, {
                    backgroundColor: bgGrads[closestIndex],
                    duration: 0.5,
                });
                // Spring-grow animation for green bars
                gsap.fromTo(
                    addSpringGrowVar,
                    { scaleY: 0, transformOrigin: "bottom" },
                    {
                        scaleY: 1,
                        duration: 1.5,
                        stagger: 0,
                        ease: "elastic.out(0.5, 0.3)",
                    }
                );
                // Fade animation for horizontal gray bars
                gsap.fromTo(
                    addFadeVar,
                    { opacity: 0 },
                    {
                        opacity: 1,
                        duration: 1,
                        stagger: 0,
                        ease: "power2.out",
                    }
                );
            }
        });
        // Update s2-arc-icon opacities (updated here)
        iconsSelector.forEach((icon, idx) => {
            const distance = Math.abs(idx - closestIndex);
            const isInVisibilityWindow = distance <= 2; // Show only if within 2 positions

            gsap.to(icon, {
                opacity: isInVisibilityWindow ? (idx === closestIndex ? 1 : 0.35) : 0, // Hide icons outside window
                // scale: idx === closestIndex ? 1.25 : (isInVisibilityWindow ? 0.9 : 0.5),
                scale: idx === closestIndex ? 1.25 : isInVisibilityWindow ? 0.9 : 0.7,
                duration: 0.4,
                ease: "power2.out",
                onStart: () => {
                    icon.style.pointerEvents = isInVisibilityWindow ? 'auto' : 'none';
                }
            });
        });
    }
}

// Set initial positions
setIconPositions(0);

// Create the scroll trigger animation
gsap.to({}, {
    scrollTrigger: {
        trigger: '#s2-animation-section',
        start: 'top top',
        end: 'bottom bottom',
        scrub: 50, // responsiveness of block re-renders also controls the duration of snap detection
        snap: {

            // added snap here
            snapTo: (progress) => {
                // Calculate snapping points based on the number of icons
                const snapPoints = Array.from({ length: totalIcons }, (_, i) => i / (totalIcons - 1));
                return gsap.utils.snap(snapPoints, progress);
            },
            duration: 0.3, // Optional: Snap animation duration
            directional: true, // Snap in the direction of scrolling
        },

        onUpdate: (self) => {
            setIconPositions(self.progress);
            let closestIndex = -1;
            let minDistance = Infinity;
            iconsSelector.forEach((icon, index) => {
                const rect = icon.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const distanceToCenter = Math.sqrt(
                    (window.innerWidth / 2 - centerX) ** 2
                    +
                    (window.innerHeight / 2 - centerY) ** 2
                );
                if (distanceToCenter < minDistance) {
                    minDistance = distanceToCenter;
                    closestIndex = index;
                }
            });
            updateVisibleImage(closestIndex);
        },
    },
});

// Initial animation for icons appearing
gsap.from(iconsSelector, {
    scale: 0,
    opacity: 0,
    duration: 1,
    stagger: 0.1,
    ease: 'back.out',
});

// Add click handlers to icons
iconsSelector.forEach((icon, index) => {
    // Hover animations
    icon.addEventListener('mouseenter', () => {
        gsap.to(icon, { scale: 1.1, duration: 0.3, ease: 'back.out' });
    });
    icon.addEventListener('mouseleave', () => {
        gsap.to(icon, { scale: 1, duration: 0.3, ease: 'back.out' });
    });
    // Click to center animation
    icon.addEventListener('click', () => {
        const targetProgress = getTargetProgressForIndex(index);
        const section = document.querySelector('#s2-animation-section');
        const scrollAmount = targetProgress * (section.offsetHeight - window.innerHeight);
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: scrollAmount,
                autoKill: false,
            },
            ease: 'power2.inOut',
        });
    });
});
</script>
   
</body>

</html>`);
      doc.close();
    }
  }, []);

  return (
    <iframe
      ref={iframeRef}
      style={{ width: "100%", height: "600px", border: "none" }}
      title="Tachy Slider"
    />
  );
}

