import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    GoglLogin,
    AddMobileNumber,
    SendOtp,
    clearStatus,
} from "../../../Reducer/authSlice";
import PrivacyPolicy from "../PrivacyPolicy";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const schema = Yup.object().shape({
   
    phone: Yup.string()
        .min(10, "Phone number must be minimum 10 digits")
        .max(12, "Phone must be less than 12 digits")
        .required("Phone number is required"),
    mobile_otp: Yup.string().required("Mobile otp is required"),
   
   
});
const countryCodes = [
    { code: "+1", country: "USA/Canada" },
    { code: "+7", country: "Russia/Kazakhstan" },
    { code: "+20", country: "Egypt" },
    { code: "+27", country: "South Africa" },
    { code: "+30", country: "Greece" },
    { code: "+31", country: "Netherlands" },
    { code: "+32", country: "Belgium" },
    { code: "+33", country: "France" },
    { code: "+34", country: "Spain" },
    { code: "+36", country: "Hungary" },
    { code: "+39", country: "Italy" },
    { code: "+40", country: "Romania" },
    { code: "+41", country: "Switzerland" },
    { code: "+43", country: "Austria" },
    { code: "+44", country: "UK" },
    { code: "+45", country: "Denmark" },
    { code: "+46", country: "Sweden" },
    { code: "+47", country: "Norway" },
    { code: "+48", country: "Poland" },
    { code: "+49", country: "Germany" },
    { code: "+51", country: "Peru" },
    { code: "+52", country: "Mexico" },
    { code: "+53", country: "Cuba" },
    { code: "+54", country: "Argentina" },
    { code: "+55", country: "Brazil" },
    { code: "+56", country: "Chile" },
    { code: "+57", country: "Colombia" },
    { code: "+58", country: "Venezuela" },
    { code: "+60", country: "Malaysia" },
    { code: "+61", country: "Australia" },
    { code: "+62", country: "Indonesia" },
    { code: "+63", country: "Philippines" },
    { code: "+64", country: "New Zealand" },
    { code: "+65", country: "Singapore" },
    { code: "+66", country: "Thailand" },
    { code: "+81", country: "Japan" },
    { code: "+82", country: "South Korea" },
    { code: "+84", country: "Vietnam" },
    { code: "+86", country: "China" },
    { code: "+90", country: "Turkey" },
    { code: "+91", country: "India" },
    { code: "+92", country: "Pakistan" },
    { code: "+93", country: "Afghanistan" },
    { code: "+94", country: "Sri Lanka" },
    { code: "+95", country: "Myanmar" },
    { code: "+98", country: "Iran" },
    { code: "+211", country: "South Sudan" },
    { code: "+212", country: "Morocco" },
    { code: "+213", country: "Algeria" },
    { code: "+216", country: "Tunisia" },
    { code: "+218", country: "Libya" },
    { code: "+220", country: "Gambia" },
    { code: "+221", country: "Senegal" },
    { code: "+222", country: "Mauritania" },
    { code: "+223", country: "Mali" },
    { code: "+224", country: "Guinea" },
    { code: "+225", country: "Ivory Coast" },
    { code: "+226", country: "Burkina Faso" },
    { code: "+227", country: "Niger" },
    { code: "+228", country: "Togo" },
    { code: "+229", country: "Benin" },
    { code: "+230", country: "Mauritius" },
    { code: "+231", country: "Liberia" },
    { code: "+232", country: "Sierra Leone" },
    { code: "+233", country: "Ghana" },
    { code: "+234", country: "Nigeria" },
    { code: "+235", country: "Chad" },
    { code: "+236", country: "Central African Republic" },
    { code: "+237", country: "Cameroon" },
    { code: "+238", country: "Cape Verde" },
    { code: "+239", country: "Sao Tome and Principe" },
    { code: "+240", country: "Equatorial Guinea" },
    { code: "+241", country: "Gabon" },
    { code: "+242", country: "Congo" },
    { code: "+243", country: "DR Congo" },
    { code: "+244", country: "Angola" },
    { code: "+245", country: "Guinea-Bissau" },
    { code: "+248", country: "Seychelles" },
    { code: "+249", country: "Sudan" },
    { code: "+250", country: "Rwanda" },
    { code: "+251", country: "Ethiopia" },
    { code: "+252", country: "Somalia" },
    { code: "+253", country: "Djibouti" },
    { code: "+254", country: "Kenya" },
    { code: "+255", country: "Tanzania" },
    { code: "+256", country: "Uganda" },
    { code: "+257", country: "Burundi" },
    { code: "+258", country: "Mozambique" },
    { code: "+260", country: "Zambia" },
    { code: "+261", country: "Madagascar" },
    { code: "+262", country: "Reunion" },
    { code: "+263", country: "Zimbabwe" },
    { code: "+264", country: "Namibia" },
    { code: "+265", country: "Malawi" },
    { code: "+266", country: "Lesotho" },
    { code: "+267", country: "Botswana" },
    { code: "+268", country: "Eswatini" },
    { code: "+269", country: "Comoros" },
    { code: "+290", country: "Saint Helena" },
    { code: "+291", country: "Eritrea" },
    { code: "+297", country: "Aruba" },
    { code: "+298", country: "Faroe Islands" },
    { code: "+299", country: "Greenland" },
    { code: "+350", country: "Gibraltar" },
    { code: "+351", country: "Portugal" },
    { code: "+352", country: "Luxembourg" },
    { code: "+353", country: "Ireland" },
    { code: "+354", country: "Iceland" },
    { code: "+355", country: "Albania" },
    { code: "+356", country: "Malta" },
    { code: "+357", country: "Cyprus" },
    { code: "+358", country: "Finland" },
    { code: "+359", country: "Bulgaria" },
    { code: "+370", country: "Lithuania" },
    { code: "+371", country: "Latvia" },
    { code: "+372", country: "Estonia" },
    { code: "+373", country: "Moldova" },
    { code: "+374", country: "Armenia" },
    { code: "+375", country: "Belarus" },
    { code: "+376", country: "Andorra" },
    { code: "+377", country: "Monaco" },
    { code: "+378", country: "San Marino" },
    { code: "+379", country: "Vatican" },
    { code: "+380", country: "Ukraine" },
    { code: "+381", country: "Serbia" },
    { code: "+382", country: "Montenegro" },
    { code: "+383", country: "Kosovo" },
    { code: "+385", country: "Croatia" },
    { code: "+386", country: "Slovenia" },
    { code: "+387", country: "Bosnia and Herzegovina" },
    { code: "+389", country: "North Macedonia" },
];
export default function AddmobileNumber() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [emailll, setEmail] = useState("");
    const [phoneee, setPhoneee] = useState("");
    const [otp1, setotp1] = useState();
    const [otp2, setotp2] = useState();
    const [id, setId] = useState("");
    const success = useSelector((state) => state.auth.success);
    const error = useSelector((state) => state.auth.error);
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [isOtpButtonDisabled, setOtpButtonDisabled] = useState(false); // State to control button disabling
    const [isOtpButtonDisabled2, setOtpButtonDisabled2] = useState(false); // State to control button disabling
    const [selectedCode, setSelectedCode] = useState("+91");
    const auth = useSelector((state) => state.auth.auth);
    useEffect(() => {
        if (auth?.mobile) {
            navigate("/Dashboard"); // Navigate to the dashboard if auth.mobile exists
        }
    }, [auth, navigate]);
    
    useEffect(() => {
        if (success && success.status === 200) {
            if (success.data.type && success.data.type) {
                setId(success.data.data);
                Swal.fire({
                    title: "Congratulation!",
                    text: success.data.message,
                    icon: "success",
                });
            } else {
                Swal.fire({
                    title: "Congratulation!",
                    text: success.data.message,
                    icon: "success",
                });
                dispatch(clearStatus());
                setTimeout(() => {
                    navigate("/Dashboard");
                }, 2000);
            }
        }
    }, [success]);

    const onSubmit = async (datas) => {
        const { mobile_otp, email_otp } = datas;

        let formData = new FormData();
       
        formData.append("userid", auth._id);
        formData.append('id', id);
        formData.append("mobile", datas.phone);
        formData.append("selectedCode", selectedCode);
        formData.append("mobile_otp", datas.mobile_otp);
        dispatch(AddMobileNumber(formData));
    };

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: "Error!",
                    text: error.data.message,
                    icon: "error",
                });
                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: "Error!",
                    text: error.data.message,
                    icon: "error",
                });
                dispatch(clearStatus());
                break;
        }
    }

    const onError = (errors) => {
        console.log(errors);
    };

    async function sendOtp(type) {
        
        if (type == "phone") {
            if (!phoneee) {
                alert("Please fill in the mobile number");
            } else if (!/^\d{10,12}$/.test(phoneee)) {
                alert("Please enter a valid mobile number");
            } else {
                let data = { type: type, data: selectedCode+""+phoneee, id: id };
                dispatch(SendOtp(data));
                disableOtpButton();
            }
        }
        if (type == "email") {
            if (!emailll) {
                alert("Please fill in the email address");
            } else if (!/\S+@\S+\.\S+/.test(emailll)) {
                alert("Please enter a valid email address");
            } else {
                let data = { type: type, data: emailll, id: id };
                dispatch(SendOtp(data));
                disableOtpButton2();
            }
        }
    }

    const disableOtpButton = () => {
        setOtpButtonDisabled(true);
        setTimeout(() => {
            setOtpButtonDisabled(false);
        }, 30000); // 30 seconds
    };
    const disableOtpButton2 = () => {
        setOtpButtonDisabled2(true);
        setTimeout(() => {
            setOtpButtonDisabled2(false);
        }, 30000); // 30 seconds
    };

    const handleEmailChange = (e) => {
        if (e.target.name == "email") {
            setEmail(e.target.value);
            setotp2(""); // Clear email OTP value when email is changed
        } else {
            setPhoneee(e.target.value);
            setotp1("");
        }
    };

  

    

   

    return (
        <>
            <section id="login_sec" class="sign_up">
                <div class="container-fluid p-0 h-100">
                    <div class="row h-100">
                        <div class="col-lg-5 col-md-5 order pe-md-0">
                            <div class="box_one">
                                <div class="login-img h-100">
                                    <img
                                        className="w-100 h-100"
                                        src="/images/loginnew.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-7 ps-md-0">
                            <div class="box-two h-100">
                                <form
                                    onSubmit={handleSubmit(onSubmit, onError)}
                                >
                                    <div class="text-center">
                                        <Link to="/">
                                            {" "}
                                            <img
                                                class="logo_login"
                                                src="images/Logo.png"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <h2>Add Mobile Number </h2>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group mess">
                                                <label class="form-label">
                                                Country Code
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="countryCode"
                                                    {...register("countryCode", { required: true })}
                                                    value={selectedCode}
                                                    onChange={(e) => setSelectedCode(e.target.value)}
                                                    >
                                                    {countryCodes.map((item, index) => (
                                                        <option key={index} value={item.code}>
                                                        {item.code} ({item.country})
                                                        </option>
                                                    ))}
                                                    </select>
                                                

                                                {errors.countryCode && (
                                                    <p
                                                        className={
                                                            "error__feedback"
                                                        }
                                                    >
                                                        {errors.countryCode.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group mess">
                                                <label class="form-label">
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="tel"
                                                    class="form-control"
                                                    {...register("phone")}
                                                    onChange={handleEmailChange}
                                                />
                                                <i class="bi bi-telephone"></i>
                                                <span
                                                    // onClick={() => sendOtp('phone')}
                                                    onClick={() =>
                                                        !isOtpButtonDisabled &&
                                                        sendOtp("phone")
                                                    }
                                                    style={{
                                                        cursor: isOtpButtonDisabled
                                                            ? "not-allowed"
                                                            : "pointer",
                                                        opacity:
                                                            isOtpButtonDisabled
                                                                ? 0.5
                                                                : 1,
                                                    }}
                                                >
                                                    {isOtpButtonDisabled
                                                        ? "Wait 30s..."
                                                        : "Send OTP"}
                                                </span>
                                                {errors.phone && (
                                                    <p
                                                        className={
                                                            "error__feedback"
                                                        }
                                                    >
                                                        {errors.phone.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    Enter OTP sent on Mobile
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="mobile_otp"
                                                    {...register("mobile_otp")}
                                                />
                                                {errors.mobile_otp && (
                                                    <p
                                                        className={
                                                            "error__feedback"
                                                        }
                                                    >
                                                        {
                                                            errors.mobile_otp
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            {/* <h3>Please read and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                                                    conditions</a>
                                            </h3> */}
                                            {/* <div class="form-check mb-2">

                                                <input class="form-check-input" type="checkbox" value="true" id="flexCheckDefault" {...register('checkboxx')} />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                I confirm that I have read and accepted the terms and conditions
                                                </label>
                                                {errors.checkboxx && <p className={"error__feedback"}>{errors.checkboxx.message}</p>}
                                            </div> */}

                                            {/* <h3>Please read <a onClick={() => openPrivacyPolicy()}>Privacy
                                                Policy</a> and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                                                    conditions</a>
                                            </h3> */}
                                            {/* <h3>Please read <a data-bs-toggle="modal" data-bs-target="#exampleModal">Privacy
                                                Policy</a> and agree to the terms and
                                                conditions
                                            </h3> */}
                                            <div class="sign_up_width">
                                                <div class="text-center">
                                                    <button type="submit">
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );
}

