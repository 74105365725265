import { Link } from "react-router-dom";


export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (<footer id="footer">
        <section id="footer" style={{ backgroundImage: `url(${"images/footer-before.png"})` }}>
            <div class="container">
                <div class="row">

                <div className="set">
                                <img src="/images/RoundImage.png" />
                                <h6>Dedicated to</h6>
                                <h5>His Divine Grace   A.C.  Bhaktivedanta Swami <span>Srila Prabhupada</span> </h5>
                            </div>

                    <div class="footer_logo">
                        <a href="./index.html"><img src="images/Logo.png" alt="" /></a>
                    </div>

                    <div class="col-lg-4">
                        <h2>Vedic Marriage</h2>
                        <p>Introducing VedicMarriage.ai, the game-changer in matchmaking for today's dynamic generation! We're not just another startup; we're on a mission to bring the ancient wisdom of Indian Vedic culture into modern relationships. </p>
                    </div>

                    <div class="col-lg-5 col-md-7">
                        <h3>Quick Links</h3>
                        <div class="row">
                            <div class="col-6">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/KundaliMatching">Kundali Matching</Link></li>
                                    {/* <li><Link to="/WeddingVenuePlanner">Wedding Venue Planner</Link></li> */}
                                    <li><Link to="/MarriageCouncelling">Marriage Counselling</Link></li>
                                    <li><Link to="/AboutUs">About Us</Link></li>
                                    <li><Link to="/refund-policy">Refund Policy</Link></li>
                                </ul>
                            </div>

                            <div class="col-6">
                                <ul>
                                    {/* <li><a href="/TermsAndConditions">Our Team</a></li> */}
                                    <li><Link to="/MakerSignup">Join us as Matchmaker</Link></li>
                                    <li><a href="/TermsAndConditions-Privacy-Policy">terms and conditions and privacy policy</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-5 flex_socail">
                        <div class="socail_icon">
                            <h3>Contact us</h3>
                            <h6>Write to us at <Link to="/AboutUs" >support@vedicmarriage.ai</Link> </h6>

                            <ul>
                                <li><a href="https://www.instagram.com/vedicmarriageai/" target="_blank"><img src="/images/instagram.png" alt="" /></a></li>
                                <li><a href="https://www.facebook.com/profile.php?id=61557746071385" target="_blank"><img src="/images/Meta.png" alt="" /></a></li>
                                <li><a href="https://www.youtube.com/channel/UCqjogu8hnGl3zt6qUjRdFig" target="_blank"><img src="/images/youtube.png" alt="" /></a></li>
                                <li><a href="https://www.linkedin.com/in/vedicmarriage/" target="_blank"><img src="/images/Likedin.png" alt="" /></a></li>
                            </ul>

                         



                        </div>
                    </div>

                </div>
            </div>
        </section>
    </footer>);
}
