import {
    NavLink,
    useNavigate,
    Link,
    useLocation,
    useParams,
} from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ApplyPromo } from "../../../Reducer/StepForms/orderSummarySlice";
import QR from "./QR";
import {
    UpdatePricing,
    clearPriceState,
} from "../../../Reducer/StepForms/pricingSlice";

export default function OrderSummary() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [promoCode, setPromoCode] = useState("");
    const [refCode, setRefCode] = useState("");
    const [openQR, setQR] = useState(false);
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const location = useLocation();
    const orderData = location.state;
    const auth = useSelector((state) => state.auth.auth);

    const handleClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        console.log("orderData", orderData);
        // getData();
        if (orderData) {
            let amount = (orderData.price * 18) / 100;
            setGst(amount);
            calculateAmount(amount);
        }
    }, [orderData]);

    // async function getData() {
    //     dispatch(GetCoupons());
    // }

    const calculateAmount = (amount) => {
        console.log("gst", gst, orderData.price);
        let totl = orderData.price + amount;
        setTotal(totl);
    };

    const onApply = async (typ, code) => {
        console.log("typ", typ);
        if (code === "") {
            alert("Please add code!");
            return;
        }
        let promoCheck = {
            uid: auth._id,
            promo: promoCode,
            total: total,
            typ: typ,
        };
        let check = await dispatch(ApplyPromo(promoCheck));
        if (check && check.payload && check.payload.data.data) {
            let newTotal = total - check.payload.data.data;
            setTotal(newTotal);
            Swal.fire({
                title: "Congratulation!",
                text: check.payload.data.message,
                icon: "success",
            });
        } else {
            Swal.fire({
                title: "Error!",
                text: check.payload.data.message,
                icon: "error",
            });
        }
    };

    const payment = async () => {
        let formData = new FormData();
        formData.append("uid", auth._id);
        formData.append("plan", orderData.planName);
        formData.append("plan_id", orderData._id);
        formData.append("promoCode", promoCode);
        formData.append("referral", refCode);
        formData.append("plan_amount", total);
        let res = await dispatch(UpdatePricing(formData));
        if (res && res.payload && res.payload.data) {
            if (orderData.planName === "Free" || total===0) {
                Swal.fire({
                    title: "Congratulation!",
                    text: res.payload.data.message,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/UserDashboard");
                    }
                });
                dispatch(clearPriceState());
            }else{
                const { redirectUrl } = res.payload.data;
                if(redirectUrl !==''){
                    window.location.href = redirectUrl;
                }else{
                    Swal.fire({
                        title: "Error",
                        text: res.payload.data.message,
                        icon: "error",
                    });
                }
                
            }
           
        } else {
            Swal.fire({
                title: "Error!",
                text: res.payload.data.message,
                icon: "error",
            });
            dispatch(clearPriceState());
        }
    };

    const handleChange = (e) => {
        setPromoCode(e.target.value);
    };
    const handleChange2 = (e) => {
        setRefCode(e.target.value);
    };

    return (
        <>
            <div
                class="modal fade show"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        {/* <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Order Summary</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                        </div> */}
                        <div class="modal-body">
                            <div class="order_summary m-0 p-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="box">
                                            <h2>
                                                <a onClick={handleClick}>
                                                    <i class="bi bi-arrow-left"></i>
                                                </a>
                                                Order Summary
                                            </h2>
                                            <div class="set mt-3">
                                                <h3>
                                                    {orderData &&
                                                        orderData.planName}{" "}
                                                    Plan
                                                </h3>
                                                <h4>
                                                    Rs.{" "}
                                                    {orderData &&
                                                        orderData.price}
                                                </h4>
                                                <h5>
                                                    <i class="bi bi-check2"></i>
                                                    {orderData &&
                                                        orderData.connectionsCount}{" "}
                                                    Connections{" "}
                                                    <span>(per month)</span>
                                                </h5>
                                                <h5>
                                                    <i class="bi bi-check2"></i>
                                                    {orderData &&
                                                        orderData.messagesCount}{" "}
                                                    Personal Messages{" "}
                                                    <span>(per month)</span>
                                                </h5>
                                                <h5>
                                                    <i class="bi bi-check2"></i>
                                                    {orderData &&
                                                        orderData.contactCount}{" "}
                                                    Contact Details{" "}
                                                    <span>(per month)</span>
                                                </h5>
                                                <h5>
                                                    <i class="bi bi-check2"></i>
                                                    {orderData &&
                                                        orderData.Validity}{" "}
                                                    Months Validity
                                                </h5>
                                                <h5>
                                                    {orderData &&
                                                    orderData.planName &&
                                                    orderData.planName ===
                                                        "Vedic Recommender" ? (
                                                        <i class="bi bi-check2"></i>
                                                    ) : (
                                                        <i class="bi bi-x-lg"></i>
                                                    )}
                                                    Matchmaker Recommendations
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="box_two">
                                            <h2>Payment Summary</h2>
                                            <div class="set_two">
                                                <h3>
                                                    {orderData &&
                                                        orderData.planName}{" "}
                                                    Plan <a>Remove</a>
                                                </h3>
                                                <p>
                                                    Rs.{" "}
                                                    {orderData &&
                                                        orderData.price}
                                                </p>
                                                <h4>
                                                    Price{" "}
                                                    <span>
                                                        Rs.{" "}
                                                        {orderData &&
                                                            orderData.price}
                                                    </span>
                                                </h4>
                                                <h4>
                                                    18% GST{" "}
                                                    <span>Rs. {gst} </span>
                                                </h4>
                                                <div class="form-group">
                                                    <label class="form-label">
                                                        Discount Code & Vouchers
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Promo Code"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src="images/Promo.png"
                                                        alt=""
                                                    />
                                                    <span
                                                        onClick={() =>
                                                            onApply(
                                                                "Promo",
                                                                promoCode
                                                            )
                                                        }
                                                    >
                                                        Apply
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Referral Code"
                                                        onChange={handleChange2}
                                                    />
                                                    <img
                                                        src="images/Referaal.png"
                                                        alt=""
                                                    />
                                                    <span
                                                        onClick={() =>
                                                            onApply(
                                                                "Referral",
                                                                refCode
                                                            )
                                                        }
                                                    >
                                                        Apply
                                                    </span>
                                                </div>
                                                <h5>
                                                    Subtotal{" "}
                                                    <span>Rs. {total}</span>
                                                </h5>
                                                <h6>
                                                    Total{" "}
                                                    <span>Rs. {total}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 text-end mt-5">
                                        {/* {orderData && orderData.planName === "Free" ?
                                            <button class="btn Make_Payment" onClick={() => payment("Free")}>Make Payment</button> : <button class="btn Make_Payment" onClick={() => payment("Other")}>Make Payment</button>
                                        } */}
                                        <button
                                            class="btn Make_Payment"
                                            onClick={() => payment("Other")}
                                        >
                                            Make Payment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openQR === true && <QR setQR={setQR} />}
        </>
    );
}
