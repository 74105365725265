// import { initializeApp } from 'firebase/app';
// const firebaseConfig = {
//     apiKey: "AIzaSyDZ2zoYm5HkfmirUxvHLBe9Wyf1ehGutt0",
//     authDomain: "vedicmarriage-223ba.firebaseapp.com",
//     projectId: "vedicmarriage-223ba",
//     storageBucket: "vedicmarriage-223ba.appspot.com",
//     messagingSenderId: "383102962422",
//     appId: "1:383102962422:web:4c9449d94c3d11e4c6bad7",
//     measurementId: "G-692L8XM6M3"
// };

// const firebase= initializeApp(firebaseConfig);

// export default firebase;
import firebase from "firebase/app";
import "firebase/database"; // Import the database module explicitly

const firebaseConfig = {
  apiKey: "AIzaSyDZ2zoYm5HkfmirUxvHLBe9Wyf1ehGutt0",
  authDomain: "vedicmarriage-223ba.firebaseapp.com",
  projectId: "vedicmarriage-223ba",
  storageBucket: "vedicmarriage-223ba.appspot.com",
  messagingSenderId: "383102962422",
  appId: "1:383102962422:web:4c9449d94c3d11e4c6bad7",
  measurementId: "G-692L8XM6M3",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export the database instance
const database = firebase.database();
export { database };
export default firebase;


