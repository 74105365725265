import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import "./style.css";

const Refundpolicy = () => {
    return (
        <>
            <Header />
            <div className="repo">
            <div className="containerrefundpolicy">
                <h1>Refund and Cancellation Policy</h1>
                <p>
                    At <strong>VedicMarriage.ai</strong>, we value your trust
                    and aim to ensure transparency and fairness in all
                    interactions. Please review the following refund and
                    cancellation policy, which is designed to address various
                    scenarios while maintaining compliance with international
                    consumer protection laws.
                </p>

                <h2>1. Refund Policy</h2>
                <ul>
                    <li>
                        <strong>
                            1.1. No Refunds for Subscription Plans or Add-On
                            Services:
                        </strong>{" "}
                        All payments made towards subscription plans (Free,
                        Premium, Premium Plus, or Vedic Recommender Plan) and
                        add-on services are final and non-refundable. Refunds
                        will only be processed in accordance with applicable
                        consumer protection laws.
                    </li>
                    <li>
                        <strong>1.2. Duplicate Payments:</strong> In the event
                        of duplicate payments made inadvertently, the excess
                        amount will be refunded upon verification by our support
                        team. Requests for refunds must be sent to{" "}
                        <a href="mailto:support@vedicmarriage.ai">
                            support@vedicmarriage.ai
                        </a>{" "}
                        with proof of the transaction, and refunds will be
                        processed within <strong>15 business days</strong>.
                        If you are eligible for Refund then refund will be credited within 15 days to the original payment method
                    </li>
                    <li>
                        <strong>
                            1.3. Refunds for Service Delivery Failures:
                        </strong>{" "}
                        If a service cannot be delivered due to technical issues
                        or system errors solely on our end, we will either issue
                        a refund or provide an equivalent service credit.
                        Refunds in such cases will be processed only after
                        verification.
                        If you are eligible for Refund then refund will be credited within 15 days to the original payment method
                    </li>
                    <li>
                        <strong>1.4. Refund Request Procedure:</strong> Refund
                        requests must comply with this policy and be submitted
                        via email to{" "}
                        <a href="mailto:support@vedicmarriage.ai">
                            support@vedicmarriage.ai
                        </a>
                        , including the following details:
                        <ul>
                            <li>Registered email ID or user ID</li>
                            <li>Payment transaction details</li>
                            <li>Description of the issue</li>
                        </ul>
                    </li>
                </ul>

                <h2>2. Cancellation Policy</h2>
                <ul>
                    <li>
                        <strong>
                            2.1. Subscription Renewals and Cancellations:
                        </strong>{" "}
                        Subscriptions are set to renew automatically unless
                        canceled by the user before the renewal date. Canceling
                        a subscription will stop the auto-renewal for the next
                        billing cycle, but no refunds will be provided for the
                        current billing period.
                    </li>
                    <li>
                        <strong>2.2. Profile Deactivation or Deletion:</strong>{" "}
                        Members may deactivate their profiles to suspend
                        services temporarily without deleting account data.
                        Permanent profile deletions must follow our deletion
                        process and do not entitle members to any refund for
                        unused services.
                    </li>
                </ul>

                <h2>3. Jurisdiction-Specific Exceptions</h2>
                <p>
                    This policy complies with local consumer protection laws.
                    Users located in jurisdictions with mandatory refund or
                    cancellation rights (e.g., European Union or specific U.S.
                    states) will have their cases handled in accordance with
                    those laws, overriding this policy where applicable.
                </p>

                <h2>4. Dispute Resolution</h2>
                <p>
                    In the event of disputes, users are required to first
                    mediate directly with <strong>VedicMarriage.ai</strong>{" "}
                    through our support team. Should mediation fail, unresolved
                    disputes will be governed by the laws of{" "}
                    <strong>India</strong> and resolved exclusively in the
                    courts of <strong>Bangalore, Karnataka</strong>.
                </p>

                <h2>5. Force Majeure</h2>
                <p>
                    We are not responsible for delays or service failures caused
                    by circumstances beyond our control, including but not
                    limited to natural disasters, government restrictions,
                    cyberattacks, pandemics, or other unforeseen events.
                </p>

                <h2>6. Limitation of Liability</h2>
                <p>
                    Our liability in connection with refunds, cancellations, or
                    other claims is strictly limited to the amount paid for the
                    disputed service. Under no circumstances will we be liable
                    for indirect, incidental, or consequential damages.
                </p>

                <h2>7. Amendments to the Policy</h2>
                <p>
                    <strong>VedicMarriage.ai</strong> reserves the right to
                    modify or update this refund and cancellation policy at any
                    time. Changes will be communicated to users via the platform
                    and will take effect immediately upon posting.
                </p>

                <p class="contact">
                    For further assistance, please contact us at{" "}
                    <a href="mailto:support@vedicmarriage.ai">
                        support@vedicmarriage.ai
                    </a>
                    .
                </p>

                <p>
                        <strong>This site is managed by Mrs. Rebati Mallik</strong><br />
                        <strong>Address: Jaganathapur, Bhadrak, Odisha</strong>
                </p>

            </div></div>
            <Footer />
        </>
    );
};
export default Refundpolicy;
