import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Preferred_Marital_Status, heightData, Prefered_Relgion, Prefered_Community, Prefered_Qualifications, Prefered_languages, Prefered_proffessional_bkgrd, Prefered_IncomeList, Prefered_food, Prefered_manglik, Prefered_cultualVal, Prefered_Initiation, Prefered_Affiliation, ChildWish } from "../../Data/content";
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";
import Select from 'react-select';
import Swal from 'sweetalert2';
import { GetPref, SavePreference, clearPrefState } from "../../../../Reducer/StepForms/preferenceSlice";
import { UserDetail } from "../../../../Reducer/UserDashboardSlices/userSlice";


export default function Preferences() {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [countryId, setCountryId] = useState(null);
    const [CountryName, setCountry] = useState(null);
    const [stateId, setstateId] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [data, setData] = useState({});
    const [spritual, setSpritual] = useState(false);
    const success = useSelector(state => state.prefernce.success);
    const error = useSelector(state => state.prefernce.error);
    const [errors, setFormErrors] = useState({});
    const Prefered_proffessional_background = Prefered_proffessional_bkgrd.sort();
    const [selectedLanguages, setSelectedLanguages] = useState([{ label: "Doesn't matter", value: "Doesn't matter" }]);
    const options = Prefered_languages.map(language => ({ value: language, label: language }));
    const [selectedFood, setSelectedFood] = useState([{ label: "Doesn't matter", value: "Doesn't matter" }]);
    const foodOptions = Prefered_food.map(food => ({ value: food, label: food }));
    const [selectedAffliations, setSelectedAffliations] = useState([]);
    const affiliationOptions = Prefered_Affiliation.map(aff => ({ value: aff, label: aff }));
    const [selectedMaritalSta, setSelectedMaritalSta] = useState([]);
    const Preferred_MaritalOP = Preferred_Marital_Status.map(mt => ({ value: mt, label: mt }));
    const [msg, setMsg] = useState("");
    const data2 = useSelector(state => state.user.data);

    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        getData();

    }, []);


    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetPref(dat));
        
        if (res.payload && Object.keys(res.payload.data.data).length > 0) {

            console.log('res.payload.data.data', res.payload.data.data);
            setData(res.payload.data.data);
            setCountry(res.payload.data.data.fullCountry);
            setState(res.payload.data.data.fullState);
            setCity(res.payload.data.data.fullCity);
            setSelectedLanguages(res.payload.data.data.prefered_language);
            setSelectedFood(res.payload.data.data.prefered_food);
            setSelectedAffliations(res.payload.data.data.prefered_affiliation);
            setSelectedMaritalSta(res.payload.data.data.prefered_marital_status);
            setSpritual(res.payload.data.spritual);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        let savedData = {
            'uid': auth._id,
            'prefered_marital_status': selectedMaritalSta && selectedMaritalSta.length > 0 && JSON.stringify(selectedMaritalSta), 'prefered_age_start': data && data.prefered_age_start ? data.prefered_age_start : "", 'prefered_age_end': data && data.prefered_age_end ? data.prefered_age_end : "", 'prefered_height_start': data && data.prefered_height_start ? data.prefered_height_start : "", 'prefered_height_end': data && data.prefered_height_end ? data.prefered_height_end : "",
            'prefered_religion': data && data.prefered_religion ? data.prefered_religion : "Doesn't matter",
            'prefered_caste': data && data.prefered_caste ? data.prefered_caste : "", 'prefered_community': data && data.prefered_community ? data.prefered_community : "Doesn't matter", 'prefered_language': selectedLanguages && selectedLanguages.length > 0 && JSON.stringify(selectedLanguages), 'prefered_qualification': data && data.prefered_qualification ? data.prefered_qualification : "Doesn't matter", 'fullCountry': CountryName ? CountryName : "", 'fullState': state ? state : "",
            'fullCity': city ? city : "", 'prefered_Proffessional_bkgrd': data && data.prefered_Proffessional_bkgrd ? data.prefered_Proffessional_bkgrd : "Doesn't matter",
            'prefered_income': data && data.prefered_income ? data.prefered_income : "", 'prefered_food': selectedFood && selectedFood.length > 0 && JSON.stringify(selectedFood), 'prefered_manglik_status': data && data.prefered_manglik_status ? data.prefered_manglik_status : "Doesn't matter",
            'prefered_cultr_val': data && data.prefered_cultr_val ? data.prefered_cultr_val : "",
            'prefered_initiation_status': data && data.prefered_initiation_status ? data.prefered_initiation_status : "", 'prefered_affiliation': selectedAffliations && selectedAffliations.length > 0 && JSON.stringify(selectedAffliations), 'children': data && data.children ? data.children : "Don't Know", 'prefered_state': data && data.prefered_state ? data.prefered_state : "",
            'prefered_country': data && data.prefered_country ? data.prefered_country : "",
            'prefered_city': data && data.prefered_city ? data.prefered_city : "",
            'other_preference': data && data.other_preference ? data.other_preference : ""
        };
        if (e.nativeEvent.submitter.name === 'draft') {
            savedData.draft = "true";
            dispatch(SavePreference(savedData));
        } else {
            let values = validate(data);
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            }
            if (chk) {
                savedData.draft = "false";
                savedData.saved = true;
                dispatch(SavePreference(savedData));

            } else {
                setFormErrors(validate(data));
            }

        }
    };


    const Next = () => {
        if (data2.selected_plan == null) {
            Navigate("/PersonalityQuestions");
        } else {
            if (data2 && data2.personalityquestions === false) {
                Navigate("/PersonalityQuestions");
            }
            else if (data2 && data2.about === false) {
                Navigate("/AboutPersonality");
            }
            else if (data2 && data2.gallery === false) {
                Navigate("/UploadGallery");
            }
            else if (data2 && data2.selfy === false) {
                Navigate("/SelfiVerification");
            }
            else if (data2 && data2.privacy === false) {
                Navigate("/PrivacySetting");
            } else {
                Navigate("/Dashboard");
            }
        }

    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearPrefState());

        } else {
            setMsg(success.data.message);
            dispatch(clearPrefState());
            Next();
            // Navigate("/PersonalityQuestions");

        }
    }

    const navigationn = () => {
        setMsg("");
        Navigate("/PersonalityQuestions");
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPrefState());
                break;

            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPrefState());
        }
    }

    const validate = (values) => {
        let error = {};
// debugger
        if (selectedMaritalSta && selectedMaritalSta.length === 0) {
            error.prefered_marital_status = "This field is required!";

        } if (!values.prefered_age_start) {
            error.prefered_age_start = "This field is required!";
        }
        if (!values.prefered_age_end) {
            error.prefered_age_end = "This field is required!";
        }
        if (!values.prefered_height_start) {
            error.prefered_height_start = "This field is required!";
        }
        if (!values.prefered_height_end) {
            error.prefered_height_end = "This field is required!";
        }
        if (!values.prefered_religion || (values.prefered_religion.trim() === "" && values.prefered_religion !== "Doesn't matter")) {
            errors.prefered_religion = "This field is required!";
        }

        if (!values.prefered_caste) {
            error.prefered_caste = "This field is required!";
        }

        if (!values.prefered_community || (values.prefered_community.trim() === "" && values.prefered_community !== "Doesn't matter")) {
            errors.prefered_community = "This field is required!";
        }

        // if (!values.prefered_language) {
        //     error.prefered_language = "This field is required!";
        // }
        if (selectedLanguages && selectedLanguages.length === 0) {
            error.prefered_language = "This field is required!";
        }
        if (selectedFood && selectedFood.length === 0) {
            error.prefered_food = "This field is required!";
        }
        if (!values.prefered_qualification || (values.prefered_qualification.trim() === "" && values.prefered_qualification !== "Doesn't matter")) {
            errors.prefered_qualification = "This field is required!";
        }


        if ((CountryName === undefined || CountryName === null) && (!values.prefered_country)) {
            error.fullCountry = "This field is required!";
        }
        if ((state === undefined || state === null) && (!values.prefered_state)) {
            error.fullState = "This field is required!";
        }
        if ((city === undefined || city === null) && (!values.prefered_city)) {
            error.fullCity = "This field is required!";
        }

        if (!values.prefered_Proffessional_bkgrd || (values.prefered_Proffessional_bkgrd.trim() === "" && values.prefered_Proffessional_bkgrd !== "Doesn't matter")) {
            errors.prefered_Proffessional_bkgrd = "This field is required!";
        }

        if (!values.prefered_income || (values.prefered_income.trim() === "" && values.prefered_income !== "Doesn't matter")) {
            errors.prefered_income = "This field is required!";
        }
        // if (!values.prefered_food) {
        //     error.prefered_food = "This field is required!";
        // }
        // if (!values.prefered_manglik_status) {
        //     error.prefered_manglik_status = "This field is required!";
        // }

        if (!values.prefered_manglik_status || (values.prefered_manglik_status.trim() === "" && values.prefered_manglik_status !== "Doesn't matter")) {
            errors.prefered_manglik_status = "This field is required!";
        }


        if (spritual === true && !values.prefered_cultr_val) {
            error.prefered_cultr_val = "This field is required!";
        }
        if (spritual === true && !values.prefered_initiation_status) {
            error.prefered_initiation_status = "This field is required!";
        }
        if (spritual === true && affiliationOptions && affiliationOptions.length === 0) {
            error.prefered_affiliation = "This field is required!";
        }
        // if (!values.prefered_affiliation) {
        //     error.prefered_affiliation = "This field is required!";
        // }

        if (!values.children || (values.children.trim() === "" && values.children !== "Don't Know")) {
            errors.children = "This field is required!";
        }

        // if (!values.other_preference) {
        //     error.other_preference = "This field is required!";
        // }
        if (values.other_preference && values.other_preference.trim().split(/\s+/).length > 100) {
            error.other_preference = "Maximum 100 words allowed.";
        }

        return error;
    }



    const handleChangeInput = (e) => {
        const value = e.target.value;
        if (e.target.name === "other_preference") {
            if (value.trim().split(/\s+/).length > 100) {
                setFormErrors({ other_preference: "Maximum 100 words allowed." });

            } else {
                setData({
                    ...data,
                    [e.target.name]: value
                });
            }
        } else {
            if (e.target.name === "prefered_country") {
                if (e.target.checked === true) {
                    setCountryId(null);
                    setCountry(null);
                    setstateId(null);
                    setCity(null);
                    setState(null);
                    setData({
                        ...data,
                        [e.target.name]: value
                    });
                } else {
                    setData({
                        ...data,
                        prefered_country: ""
                    });
                }

            } else if (e.target.name === "prefered_state") {
                if (e.target.checked === true) {
                    setstateId(null);
                    setState(null);
                    setCity(null);
                    setData({
                        ...data,
                        [e.target.name]: value
                    });
                } else {
                    setData({
                        ...data,
                        prefered_state: ""
                    });
                }

            } else if (e.target.name === "prefered_city") {
                if (e.target.checked === true) {
                    setCity(null)
                    setData({
                        ...data,
                        [e.target.name]: value
                    });
                } else {
                    setData({
                        ...data,
                        prefered_city: ""
                    });
                }
            } else {
                setData({
                    ...data,
                    [e.target.name]: value
                });
            }

        }
    }

    const handleLanguageChange = (selectedOptions) => {
        setSelectedLanguages(selectedOptions);
    };
    const handleFoodChange = (selectedOptions) => {
        setSelectedFood(selectedOptions);
    };
    const handleAffliationChange = (selectedOptions) => {
        setSelectedAffliations(selectedOptions);
    };
    const handleMtStChange = (selectedOptions) => {
        setSelectedMaritalSta(selectedOptions);
    };


    return (
        <form onSubmit={handleSubmit}>
            <div class="tab">

                <h4>Basic Preference</h4>

                <div class="row">

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Marital Status*</label>
                            <Select
                                class="form-select"
                                options={Preferred_MaritalOP}
                                isMulti
                                value={selectedMaritalSta}
                                onChange={handleMtStChange}

                            />
                            {errors.prefered_marital_status && <p className={"error__feedback"}>{errors.prefered_marital_status}</p>}

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label class="form-label">Preferred Age Range*</label>
                        <div class="row align-items-baseline">

                            <div class="col-5">
                                <div class="form-group">
                                    <input type="number" class="form-control" name="prefered_age_start" value={data && data.prefered_age_start} onChange={handleChangeInput} min="18" />

                                </div>
                                {errors.prefered_age_start && <p className={"error__feedback"}>{errors.prefered_age_start}</p>}
                            </div>

                            <div class="col-2">
                                <h6 class="diffrent_sec">to</h6>
                            </div>

                            <div class="col-5">
                                <div class="form-group">
                                    <input type="number" class="form-control" name="prefered_age_end" value={data && data.prefered_age_end} onChange={handleChangeInput} min={data && data.prefered_age_start ? data.prefered_age_start : "18"} />

                                    {errors.prefered_age_end && <p className={"error__feedback"}>{errors.prefered_age_end}</p>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="form-label">Preferred Height*</label>
                                <div class="row align-items-baseline">

                                    <div class="col-5">
                                        <div class="form-group">
                                            <select class="form-select"
                                                aria-label="Default select example" name="prefered_height_start" onChange={handleChangeInput}>

                                                <option selected disabled>Select</option>
                                                {heightData.length > 0 && heightData.map((item, i) => (
                                                    <option selected={data && data.prefered_height_start === item.value} value={item.value}>{item.item}</option>
                                                ))}
                                            </select>
                                            {errors.prefered_height_start && <p className={"error__feedback"}>{errors.prefered_height_start}</p>}
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <h6 class="diffrent_sec">to</h6>
                                    </div>

                                    <div class="col-5">
                                        <div class="form-group">
                                            <select class="form-select"
                                                aria-label="Default select example" name="prefered_height_end" onChange={handleChangeInput}>
                                                <option selected disabled>Select</option>
                                                {heightData.length > 0 && heightData.map((it, i) => (
                                                    <option selected={data && data.prefered_height_end === it.value} value={it.value}>{it.item}</option>
                                                ))}
                                            </select>
                                            {errors.prefered_height_end && <p className={"error__feedback"}>{errors.prefered_height_end}</p>}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-label">How many children you wish to have after marriage?*</label>
                                    <select class="form-select" aria-label="Default select example" name="children" onChange={handleChangeInput}>
                                        {/* <option selected disabled>Select</option> */}
                                        {ChildWish.length > 0 && ChildWish.map((item, i) => (
                                            <option selected={data && data.children === item} value={item}>{item}</option>
                                        ))}
                                    </select>   {errors.children && <p className={"error__feedback"}>{errors.children}</p>}

                                </div>
                            </div>

                        </div>
                    </div>

                    <h4>Community Preference</h4>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Religion*</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_religion" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                {Prefered_Relgion.length > 0 && Prefered_Relgion.map((item, i) => (
                                    <option selected={data && data.prefered_religion === item} value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.prefered_religion && <p className={"error__feedback"}>{errors.prefered_religion}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Caste*</label>
                            <input type="text" class="form-control" name="prefered_caste" value={data && data.prefered_caste} onChange={handleChangeInput} />
                            {errors.prefered_caste && <p className={"error__feedback"}>{errors.prefered_caste}</p>}

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Community*</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_community" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                {Prefered_Community.length > 0 && Prefered_Community.map((item, i) => (
                                    <option selected={data && data.prefered_community === item} value={item}>{item}</option>

                                ))}
                            </select>
                            {errors.prefered_community && <p className={"error__feedback"}>{errors.prefered_community}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Language*</label>
                            <Select
                                class="form-select"
                                options={options}
                                isMulti
                                value={selectedLanguages}
                                onChange={handleLanguageChange}

                            />
                            {errors.prefered_language && <p className={"error__feedback"}>{errors.prefered_language}</p>}
                        </div>
                    </div>

                    <h4>Location Preferences</h4>

                    <div class="row">

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Preferred Country*</label>
                                <CountrySelect
                                    showFlag={false}
                                    inputClassName="form-control"
                                    class="form-select ps-5"
                                    onChange={(e) => {
                                        if (e && e.id && e.name) {

                                            setCountryId(e.id);
                                            setCountry(e);
                                            setstateId(null);
                                            setCity(null);
                                            setState(null);
                                            setData({
                                                ...data,
                                                prefered_country: "",
                                                prefered_state: "",
                                                prefered_city: ""
                                            });
                                        }

                                    }
                                    }

                                    defaultValue={CountryName}
                                    placeHolder="Select Country"
                                    name="fullCountry"

                                />
                                {errors.fullCountry && <p className={"error__feedback"}>{errors.fullCountry}</p>}
                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" value="Doesn't matter" checked={data && data.prefered_country === "Doesn't matter"} id="flexCheckDefault" name="prefered_country" onChange={handleChangeInput} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Doesn’t Matter
                                </label>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Preferred State*</label>
                                <StateSelect
                                    showFlag={false}
                                    inputClassName="form-control"

                                    countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                    onChange={(e) => {
                                        if (e && e.id && e.name) {
                                            setstateId(e.id);
                                            setState(e);
                                            setCity("");
                                            setData({
                                                ...data,
                                                prefered_country: "",
                                                prefered_state: "",
                                                prefered_city: ""
                                            });
                                        } else {

                                            setState(null)
                                        }
                                    }}
                                    defaultValue={state}
                                    placeHolder="Select State"
                                    name="fullState"
                                />
                                {errors.fullState && <p className={"error__feedback"}>{errors.fullState}</p>}
                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" value="Doesn't matter" checked={data && data.prefered_state === "Doesn't matter"} id="flexCheckDefault" name="prefered_state" onChange={handleChangeInput} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Doesn’t Matter
                                </label>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Preferred City / Location*</label>
                                <CitySelect
                                    showFlag={false}
                                    inputClassName="form-control"
                                    countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                    stateid={state && state.id ? state.id : stateId}
                                    onChange={(e) => {

                                        if (e && e.id && e.name) {
                                            console.log(e);
                                            setCity(e)
                                            setData({
                                                ...data,
                                                prefered_country: "",
                                                prefered_state: "",
                                                prefered_city: ""
                                            });

                                        } else {
                                            setCity(null)
                                        }
                                    }}
                                    defaultValue={city}
                                    placeHolder="Select City"
                                    name="fullCity"
                                />
                                {errors.fullCity && <p className={"error__feedback"}>{errors.fullCity}</p>}
                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" value="Doesn't matter" checked={data && data.prefered_city === "Doesn't matter"} id="flexCheckDefault" name="prefered_city" onChange={handleChangeInput} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Doesn’t Matter
                                </label>
                            </div>
                        </div>

                    </div>

                    <h4>Educational Preferences</h4>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Qualification*</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_qualification" onChange={handleChangeInput}>

                                {/* <option selected disabled>Select</option> */}
                                {Prefered_Qualifications.length > 0 && Prefered_Qualifications.map((item, i) => (
                                    <option selected={data && data.prefered_qualification === item} value={item}>{item}</option>

                                ))}

                            </select>
                            {errors.prefered_qualification && <p className={"error__feedback"}>{errors.prefered_qualification}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Professional Background*</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_Proffessional_bkgrd" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                <option selected={data && data.prefered_Proffessional_bkgrd === "Doesn't matter"} value="Doesn't matter">Doesn't matter</option>
                                {Prefered_proffessional_background.length > 0 && Prefered_proffessional_background.map((item, i) => (
                                    <option selected={data && data.prefered_Proffessional_bkgrd === item} value={item}>{item}</option>

                                ))}

                            </select>
                            {errors.prefered_Proffessional_bkgrd && <p className={"error__feedback"}>{errors.prefered_Proffessional_bkgrd}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Annual Income*</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_income" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                {Prefered_IncomeList.length > 0 && Prefered_IncomeList.map((item, i) => (
                                    <option selected={data && data.prefered_income === item} value={item}>{item}</option>

                                ))}
                            </select>
                            {errors.prefered_income && <p className={"error__feedback"}>{errors.prefered_income}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">

                    </div>

                    <div class="col-sm-6">
                        <h4>Diet Preferences</h4>
                        <div class="form-group">
                            <label class="form-label">Food Preferences*</label>
                            <Select
                                class="form-select"
                                options={foodOptions}
                                isMulti
                                value={selectedFood}
                                onChange={handleFoodChange}

                            />

                            {errors.prefered_food && <p className={"error__feedback"}>{errors.prefered_food}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <h4>Astro Preference*</h4>
                        <div class="form-group">
                            <label class="form-label">Prefered Manglik Status</label>
                            <select class="form-select" aria-label="Default select example" name="prefered_manglik_status" onChange={handleChangeInput}>

                                {/* <option selected disabled>Select</option> */}
                                {Prefered_manglik.length > 0 && Prefered_manglik.map((item, i) => (
                                    <option selected={data && data.prefered_manglik_status === item} value={item}>{item}</option>

                                ))}
                            </select>
                            {errors.prefered_manglik_status && <p className={"error__feedback"}>{errors.prefered_manglik_status}</p>}
                        </div>
                    </div>

                    {spritual === true &&
                        <>

                            <div class="col-12">
                                <h4>Spiritual Preference</h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Preferred Cultural Values and
                                                Practices*</label>
                                            <select class="form-select" aria-label="Default select example" name="prefered_cultr_val" onChange={handleChangeInput}>

                                                <option selected disabled>Select</option>
                                                {Prefered_cultualVal.length > 0 && Prefered_cultualVal.map((item, i) => (
                                                    <option selected={data && data.prefered_cultr_val === item} value={item}>{item}</option>

                                                ))}
                                            </select>
                                            {errors.prefered_cultr_val && <p className={"error__feedback"}>{errors.prefered_cultr_val}</p>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-label">Preferred Initiation Status*</label>
                                    <select class="form-select" aria-label="Default select example" name="prefered_initiation_status" onChange={handleChangeInput}>
                                        <option selected disabled>Select</option>
                                        {Prefered_Initiation.length > 0 && Prefered_Initiation.map((item, i) => (
                                            <option selected={data && data.prefered_initiation_status === item} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {errors.prefered_initiation_status && <p className={"error__feedback"}>{errors.prefered_initiation_status}</p>}
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-label">What is your preferred partner's affiliation?*</label>
                                    <Select
                                        class="form-select"
                                        options={affiliationOptions}
                                        isMulti
                                        value={selectedAffliations}
                                        onChange={handleAffliationChange}

                                    />
                                    {errors.prefered_affiliation && <p className={"error__feedback"}>{errors.prefered_affiliation}</p>}
                                </div>
                            </div>
                        </>
                    }

                    <div class="row">
                        <h4>Partner Expectations</h4>
                        <div class="form-group">
                            <label class="form-label">What are you looking in your partner?(min 100 words)*</label>
                            <textarea name="other_preference" class="form-control" onChange={handleChangeInput} value={data && data.other_preference}>
                            </textarea>
                            {errors.other_preference && <p className={"error__feedback"}>{errors.other_preference}</p>}



                        </div>
                    </div>

                </div>
            </div>
            <div class="step_common_btn">
                <div class="row">
                    <div class="col-sm-6" >
                        <button class="btn two" type="submit" name="draft" disabled={data && data.saved && data.saved === true}>Save Draft</button>
                    </div>

                    <div class="col-sm-6 text-end">
                        <a class="btn" onClick={() => navigationn()}>Skip</a>
                        <button class="btn" type="submit"
                        >Next</button>
                    </div>
                    {msg !== "" && <p class="SucessColor">{msg}</p>}
                </div>

            </div>
        </form>
    )

}