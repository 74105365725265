import Home from "../Components/Pages/Home";
import Signup from "../Components/Pages/Signup";
import Login from "../Components/Pages/Login";
import KundaliMatching from "../Components/Pages/KundaliMatching";
import AfterMatching from "../Components/Pages/KundaliMatching/AfterMatching";
import WeddingVenuePlanner from "../Components/Pages/WeddingVenuePlanner";
import MarriageCouncelling from "../Components/Pages/MarriageCouncelling";
import AboutUs from "../Components/Pages/AboutUs";
import Refundpolicy from "../Components/Pages/Refundpolicy";
import AddmobileNumber from "../Components/Pages/AddmobileNumber";
import MakerSignup from "../Components/Pages/MakerSignup";
import SingleResource from "../Components/Pages/Tools/Resources/SingleResource";
import TermsAndConditions from "../Components/Pages/TermsAndConditions";
import ResetPassword from "../Components/Pages/ResetPassword";
import Test from "../Components/Pages/Test";
import TermPopup from "../Components/Pages/TermsAndConditions/TermPopup"
export const freeRoutes = [
    {
        path: "/testing",
        component: <Test />
    },
    {
        path: "/",
        component: <Home />,
    },
    {
        path: "/Signup",
        component: <Signup />,
    },
    {
        path: "/MakerSignup",
        component: <MakerSignup />,
    },
    {
        path: "/Login",
        component: <Login />,
    },
    {
        path: "/KundaliMatching",
        component: <KundaliMatching />,
    },
    {
        path: "/AfterMatching",
        component: <AfterMatching />,
    },
    {
        path: "/WeddingVenuePlanner",
        component: <WeddingVenuePlanner />,
    },
    {
        path: "/MarriageCouncelling",
        component: <MarriageCouncelling />,
    },
    {
        path: "/AboutUs",
        component: <AboutUs />,
    },
    {
        path: "/resource/:id",
        component: <SingleResource />,
    },
    {
        path: "/TermsAndConditions-Privacy-Policy",
        component: <TermsAndConditions />
    },
    {
        path: "/ResetPassword",
        component: <ResetPassword />
    },
    {
        path: "/accept-terms",
        component: <TermPopup />
    },
    {
        path: "/refund-policy",
        component: <Refundpolicy />
    },
    {
        path: "/add-mobile-number",
        component: <AddmobileNumber />
    }
];

export default freeRoutes;



