import React,{useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import freeRoutes from './Routes/forntendRoutes';
import userPrivateRoutes from './Routes/UserRoutes';
import { makerRoutes } from './Routes/matchMakerRoutes';

function App() {
  
  useEffect(() => {
    // const handleUnload = (event) => {
    //   // debugger
    //   // Check if the navigation is not a page refresh
    //   if (performance.getEntriesByType("navigation")[0].type === "reload") {
    //     // Clear localStorage only if it's not a refresh
    //     localStorage.clear();
    //   }
    // };

    // // Add event listener for beforeunload
    // window.addEventListener("beforeunload", handleUnload);

    // // Cleanup the event listener on component unmount
    // return () => {
    //   window.removeEventListener("beforeunload", handleUnload);
    // };
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename={'/'}>
        <Routes >

          {freeRoutes.length > 0 && freeRoutes.map((item, i) => (
            <Route exact path={item.path} element={item.component} />
          ))}

          {userPrivateRoutes.length > 0 && userPrivateRoutes.map((item, i) => (
            <Route exact path={item.path} element={<AuthRoute element={item.component} user={'User'} />} />
          ))}

          {makerRoutes.length > 0 && makerRoutes.map((item, i) => (
            <Route exact path={item.path} element={<AuthRoute element={item.component} user={'Maker'} />} />
          ))}



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

