import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { affiliation } from "../../Data/content";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import { GetSpritualInfo, SaveSpritualInfo, clearspritualState } from "../../../../Reducer/StepForms/spritualSlice";
import Swal from 'sweetalert2';

export default function Spiritual() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [form, openForm] = useState("");
    const [formData, setFormData] = useState({
        affiliated_with_spiritual_organization: "",
        affiliated_duration: "",
        sadhanaTime: "",
        other_organization_name: "",
        practicing_time_period: "",
        chanting_rounds: "",
        temple_visit: "",
        spirituality_role: "",
        spiritual_master: "",
        initiated: "",
        Initiation_name: "",
        parents_practice_Krishna: "",
        iskcon_associted_with: "",
        four_Regulative_Principles: "",
        books_that_read: "",
        selectedBooks: [],
        ekadashi_fasting: "",
        temple_associated_type: "",
        // addition data 
        attended_seminar: "",
        engaged_devotional_services: "",
        living_in_temple: "",
        Krishna_Consciousness_after_marriage: "",
        tech_of_Srila_Prabhupada: "",
        spritulGrowthMarriage: "",
        incorporating_Krishna_Consciousness: "",
        conflicts_spiritualJourny: "",
        specific_spiritual_goals: "",

        // for ftm
        spiritual_mentor: "",
        temple_services: "",
        department_name: "",
        designation_name: "",
        roles_and_experiences: ""
    });
    const locaarr = ["Gaudiya Vaishnava",
        "Madhva",
        "Sri Vaishnava",
        "Sri Sri Group",
        "Art of Living Foundation",
        "Brahma Kumaris",
        "Chinmaya Mission",
        "Isha Foundation",
    ];
    const [msg, setMsg] = useState("");
    const [errors, setFormErrors] = useState({});
    const success = useSelector(state => state.spritual.success);
    const error = useSelector(state => state.spritual.error);
    const [btnst, setBtn] = useState(false);
    const [submitType, setSubmitType] = useState("");

    const navigationn = () => {
        setMsg("");
        let name = "Preferences";
        navigate("/UserStepForms", { state: { name } });
    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearspritualState());

        } else {
            if (submitType === "draft") {
                setMsg(success.data.message);
                dispatch(clearspritualState());
            } else {
                navigationn();
                dispatch(clearspritualState());
            }
            // setBtn(true);


        }
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearspritualState());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearspritualState());
                break;
        }
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    useEffect(() => {

        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetSpritualInfo(dat));
        if (res.payload && res.payload.data.data) {
            console.log('res.payload.data.data', res.payload.data.data);
            const responseData = res.payload.data.data;
            if (responseData.affiliated_with_spiritual_organization === "ISKCON Bangalore & HKM Centers" || responseData.affiliated_with_spiritual_organization === "ISKCON") {
                openForm("Form2");
            }
            else if (responseData.affiliated_with_spiritual_organization === "Others organization") {
                openForm("Form3");
            } else if (locaarr.includes(responseData.affiliated_with_spiritual_organization)) {
                openForm("Form1");
            } else {
                openForm("");
            }
            setFormData(prevState => ({
                ...prevState,
                affiliated_with_spiritual_organization: responseData.affiliated_with_spiritual_organization,
                affiliated_duration: responseData.affiliated_duration,
                sadhanaTime: responseData.sadhanaTime,
                other_organization_name: responseData.other_organization_name,
                practicing_time_period: responseData.practicing_time_period,
                chanting_rounds: responseData.chanting_rounds,
                temple_visit: responseData.temple_visit,
                spirituality_role: responseData.spirituality_role,
                spiritual_master: responseData.spiritual_master,
                initiated: responseData.initiated,
                Initiation_name: responseData.Initiation_name,
                parents_practice_Krishna: responseData.parents_practice_Krishna,
                iskcon_associted_with: responseData.iskcon_associted_with,
                four_Regulative_Principles: responseData.four_Regulative_Principles,
                books_that_read: responseData.books_that_read,
                selectedBooks: responseData.selectedBooks,
                currently_reading: responseData.currently_reading,
                ekadashi_fasting: responseData.ekadashi_fasting,
                temple_associated_type: responseData.temple_associated_type,
                attended_seminar: responseData.attended_seminar,
                engaged_devotional_services: responseData.engaged_devotional_services,
                living_in_temple: responseData.living_in_temple,
                Krishna_Consciousness_after_marriage: responseData.Krishna_Consciousness_after_marriage,
                tech_of_Srila_Prabhupada: responseData.tech_of_Srila_Prabhupada,
                spritulGrowthMarriage: responseData.spritulGrowthMarriage,
                incorporating_Krishna_Consciousness: responseData.incorporating_Krishna_Consciousness,
                conflicts_spiritualJourny: responseData.conflicts_spiritualJourny,
                specific_spiritual_goals: responseData.specific_spiritual_goals,
                spiritual_mentor: responseData.spiritual_mentor,
                temple_services: responseData.temple_services,
                department_name: responseData.department_name,
                designation_name: responseData.designation_name,
                roles_and_experiences: responseData.roles_and_experiences,
                saved: responseData.saved,
                draft: responseData.draft,
                shareprofile: responseData.shareprofile
            }));
        }
    }

    const changeOpion = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            affiliated_duration: "",
            sadhanaTime: "",
            other_organization_name: "",
            practicing_time_period: "",
            chanting_rounds: "",
            temple_visit: "",
            spirituality_role: "",
            spiritual_master: "",
            initiated: "",
            Initiation_name: "",
            parents_practice_Krishna: "",
            iskcon_associted_with: "",
            four_Regulative_Principles: "",
            books_that_read: "",
            selectedBooks: [],
            currently_reading: "",
            ekadashi_fasting: "",
            temple_associated_type: "",
            attended_seminar: "",
            engaged_devotional_services: "",
            living_in_temple: "",
            Krishna_Consciousness_after_marriage: "",
            tech_of_Srila_Prabhupada: "",
            spritulGrowthMarriage: "",
            incorporating_Krishna_Consciousness: "",
            conflicts_spiritualJourny: "",
            specific_spiritual_goals: "",
            spiritual_mentor: "",
            temple_services: "",
            department_name: "",
            designation_name: "",
            roles_and_experiences: "",
            shareprofile:""
        });

        if (e.target.value === "ISKCON Bangalore & HKM Centers" || e.target.value === "ISKCON") {
            openForm("Form2");
        } else if (e.target.value === "Others organization") {
            openForm("Form3");

        } else if (e.target.value === "No") {
            openForm("");

        } else {
            openForm("Form1");
        }
    }

    const selcet_books = (dat) => {
        setFormData({
            ...formData,
            selectedBooks: dat
        })
    }

    const validate = (values, type) => {

        let error = {};
        if (type === "form") {
            if (!values.affiliated_with_spiritual_organization) {
                error.affiliated_with_spiritual_organization = "This field is required!";
            }
        }
        if (type === "form1") {
            if (!values.affiliated_duration) {
                error.affiliated_duration = "This field is required!";
            }
            if (!values.sadhanaTime) {
                error.sadhanaTime = "This field is required!";
            }

        } if (type === "form2") {
            // if (!values.affiliated_duration) {
            //     error.affiliated_duration = "This field is required!";
            // }
            // if (!values.sadhanaTime) {
            //     error.sadhanaTime = "This field is required!";
            // }
            if (!values.practicing_time_period) {
                error.practicing_time_period = "This field is required!";
            }
            if (!values.chanting_rounds) {
                error.chanting_rounds = "This field is required!";
            }
            if (!values.temple_visit) {
                error.temple_visit = "This field is required!";
            }
            if (!values.spirituality_role) {
                error.spirituality_role = "This field is required!";
            }
            if (!values.spiritual_master) {
                error.spiritual_master = "This field is required!";
            }
            if (!values.initiated) {
                error.initiated = "This field is required!";
            }
            if
                (values.initiated === "Yes" && !values.Initiation_name) {
                error.Initiation_name = "This field is required!";
            }
            if (!values.four_Regulative_Principles) {
                error.four_Regulative_Principles = "This field is required!";
            }
            if (!values.parents_practice_Krishna) {
                error.parents_practice_Krishna = "This field is required!";
            }
            if (formData.selectedBooks && formData.selectedBooks.length === 0) {
                error.selectedBooks = "This field is required!";
            }
            if (!values.currently_reading) {
                error.currently_reading = "This field is required!";
            }
            if (!values.ekadashi_fasting) {
                error.ekadashi_fasting = "This field is required!";
            }

            if (!values.iskcon_associted_with) {
                error.iskcon_associted_with = "This field is required!";
            }

            if (!values.temple_associated_type) {
                error.temple_associated_type = "This field is required!";
            }

            if (values.temple_associated_type && !values.attended_seminar) {
                error.attended_seminar = "This field is required!";
            }
            if (values.temple_associated_type && !values.engaged_devotional_services) {
                error.engaged_devotional_services = "This field is required!";
            }
            if (values.temple_associated_type && !values.living_in_temple) {
                error.living_in_temple = "This field is required!";
            }
            if (values.temple_associated_type && !values.Krishna_Consciousness_after_marriage) {
                error.Krishna_Consciousness_after_marriage = "This field is required!";
            }
            if (values.temple_associated_type && !values.tech_of_Srila_Prabhupada) {
                error.tech_of_Srila_Prabhupada = "This field is required!";
            }
            if (values.temple_associated_type && !values.spritulGrowthMarriage) {
                error.spritulGrowthMarriage = "This field is required!";
            }
            // if (values.temple_associated_type && !values.incorporating_Krishna_Consciousness) {
            //     error.incorporating_Krishna_Consciousness = "This field is required!";
            // }

            // if (values.temple_associated_type && !values.conflicts_spiritualJourny) {
            //     error.conflicts_spiritualJourny = "This field is required!";
            // }
            // if (values.temple_associated_type && !values.specific_spiritual_goals) {
            //     error.specific_spiritual_goals = "This field is required!";
            // }
            // if (values.temple_associated_type && !values.spiritual_mentor) {
            //     error.spiritual_mentor = "This field is required!";

            // }

            // For ftm

            // if (values.temple_associated_type === "Full-time Temple Devotee" && !values.temple_services) {
            //     error.temple_services = "This field is required!";

            // }
            // if (values.temple_associated_type === "Full-time Temple Devotee" && !values.department_name) {
            //     error.department_name = "This field is required!";

            // }
            // if (values.temple_associated_type === "Full-time Temple Devotee" && !values.designation_name) {
            //     error.designation_name = "This field is required!";

            // }
            // if (values.temple_associated_type === "Full-time Temple Devotee" && !values.roles_and_experiences) {
            //     error.roles_and_experiences = "This field is required!";

            // }
        }
        if (type === "form3") {
            if (!values.affiliated_with_spiritual_organization) {
                error.affiliated_with_spiritual_organization = "This field is required!";
            }

        }
        return error;
    }


    async function handleSubmit(e) {
        e.preventDefault();
        debugger
        let formDataSub = new FormData();
        formDataSub.append('uid', auth._id);
        formDataSub.append('affiliated_with_spiritual_organization', formData && formData.affiliated_with_spiritual_organization ? formData.affiliated_with_spiritual_organization : "");
        // form 2
        formDataSub.append('affiliated_duration', formData && formData.affiliated_duration ? formData.affiliated_duration : 0);
        formDataSub.append('sadhanaTime', formData && formData.sadhanaTime ? formData.sadhanaTime : 0);
        formDataSub.append('other_organization_name', formData && formData.other_organization_name ? formData.other_organization_name : "");
        formDataSub.append('practicing_time_period', formData && formData.practicing_time_period ? formData.practicing_time_period : 0);
        formDataSub.append('chanting_rounds', formData && formData.chanting_rounds ? formData.chanting_rounds : 0);
        formDataSub.append('temple_visit', formData && formData.temple_visit ? formData.temple_visit : "");
        formDataSub.append('spirituality_role', formData && formData.spirituality_role ? formData.spirituality_role : "");
        formDataSub.append('spiritual_master', formData && formData.spiritual_master ? formData.spiritual_master : "");
        formDataSub.append('initiated', formData && formData.initiated ? formData.initiated : "");
        formDataSub.append('Initiation_name', formData && formData.Initiation_name ? formData.Initiation_name : "");
        formDataSub.append('parents_practice_Krishna', formData && formData.parents_practice_Krishna ? formData.parents_practice_Krishna : "");
        formDataSub.append('iskcon_associted_with', formData && formData.iskcon_associted_with ? formData.iskcon_associted_with : "");
        formDataSub.append('four_Regulative_Principles', formData && formData.four_Regulative_Principles ? formData.four_Regulative_Principles : "");
        formDataSub.append('selectedBooks', formData && formData.selectedBooks && formData.selectedBooks.length > 0 ? JSON.stringify(formData.selectedBooks) : JSON.stringify([]));
        formDataSub.append('currently_reading', formData && formData.currently_reading ? formData.currently_reading : "");
        formDataSub.append('ekadashi_fasting', formData && formData.ekadashi_fasting ? formData.ekadashi_fasting : "");
        formDataSub.append('temple_associated_type', formData && formData.temple_associated_type ? formData.temple_associated_type : "");
        // additional 

        formDataSub.append('attended_seminar', formData && formData.attended_seminar ? formData.attended_seminar : "");
        formDataSub.append('engaged_devotional_services', formData && formData.engaged_devotional_services ? formData.engaged_devotional_services : "");
        formDataSub.append('living_in_temple', formData && formData.living_in_temple ? formData.living_in_temple : "");
        formDataSub.append('Krishna_Consciousness_after_marriage', formData && formData.Krishna_Consciousness_after_marriage ? formData.Krishna_Consciousness_after_marriage : "");
        formDataSub.append('tech_of_Srila_Prabhupada', formData && formData.tech_of_Srila_Prabhupada ? formData.tech_of_Srila_Prabhupada : "");
        formDataSub.append('spritulGrowthMarriage', formData && formData.spritulGrowthMarriage ? formData.spritulGrowthMarriage : "");
        formDataSub.append('incorporating_Krishna_Consciousness', formData && formData.incorporating_Krishna_Consciousness ? formData.incorporating_Krishna_Consciousness : "");
        formDataSub.append('conflicts_spiritualJourny', formData && formData.conflicts_spiritualJourny ? formData.conflicts_spiritualJourny : "");
        formDataSub.append('specific_spiritual_goals', formData && formData.specific_spiritual_goals ? formData.specific_spiritual_goals : "");
        formDataSub.append('spiritual_mentor', formData && formData.spiritual_mentor ? formData.spiritual_mentor : "");
        formDataSub.append('temple_services', formData && formData.temple_services ? formData.temple_services : "");
        formDataSub.append('department_name', formData && formData.department_name ? formData.department_name : "");
        formDataSub.append('designation_name', formData && formData.designation_name ? formData.designation_name : "");
        formDataSub.append('roles_and_experiences', formData && formData.roles_and_experiences ? formData.roles_and_experiences : "");
        formDataSub.append('shareprofile', formData?.shareprofile !== "No");
        if (e.nativeEvent.submitter.name === 'draft') {
            setSubmitType('draft');
            formDataSub.append('draft', 'true');
            dispatch(SaveSpritualInfo(formDataSub));

        } else {
            let values = {};

            if (form === "Form1") {
                values = validate(formData, "form1");
            } else if (form === "Form2") {
                values = validate(formData, "form2");
            } else if (form === "Form3") {
                values = validate(formData, "form3");
            } else {
                values = validate(formData, "form");
            }
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            } if (chk) {
                setSubmitType('next');
                formDataSub.append('draft', 'false');
                formDataSub.append('saved', true);
                dispatch(SaveSpritualInfo(formDataSub));

            } else {
                if (form === "Form1") {
                    setFormErrors(validate(formData, "form1"));
                } else if (form === "Form2") {
                    setFormErrors(validate(formData, "form2"));
                } else if (form === "Form3") {
                    setFormErrors(validate(formData, "form3"));
                } else {
                    setFormErrors(validate(formData, "form"));

                }
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div class="tab">
                <h3>Spiritual</h3>
                {/* <!-- ----- Part 1 ----- --> */}
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Are you associated/affiliated with any spiritual organization?*</label>
                            <select class="form-select" aria-label="Default select example" onChange={changeOpion} name="affiliated_with_spiritual_organization">
                                <option selected disabled>Select</option>
                                {affiliation.length > 0 && affiliation.map((item, i) => (
                                    <option selected={formData.affiliated_with_spiritual_organization === item} value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.affiliated_with_spiritual_organization && <p className={"error__feedback"}>{errors.affiliated_with_spiritual_organization}</p>}
                        </div>
                    </div>
                </div>
            </div>
            {form === "Form1" &&
                <Form1 data={formData} handleChange={handleFormChange} errors={errors} />
            }{form === "Form2" &&
                <Form2 data={formData} handleChange={handleFormChange} errors={errors} seecteBooks={selcet_books} />
            }{form === "Form3" &&
                <Form3 data={formData} handleChange={handleFormChange} errors={errors} />
            }

            <div class="step_common_btn">
                <div class="row">
                    <div class="col-sm-6" >
                        <button class="btn two" type="submit" name="draft" disabled={formData && formData.saved && formData.saved === true}>Save Draft</button>
                    </div>
                    <div class="col-sm-6 text-end">
                        <a class="btn" onClick={() => navigationn()}>Skip</a>
                        <button class="btn" type="submit"
                        >Next</button>
                    </div>
                    {msg !== "" && <p class="SucessColor">{msg}</p>}
                </div>

            </div>

        </form>
    )

}

