import {
    useLocation,
    useNavigate,

} from "react-router-dom";
import React, {  useEffect } from "react";

import Swal from "sweetalert2";




export default function PaymentStatus() {
   
    const navigate = useNavigate();
   
    const location = useLocation();
  const params = new URLSearchParams(location.search);
   

    const handleClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        const msg = params.get("msg");      
        const status = params.get("status");
        if(status==="success"){
            Swal.fire({
                title: "Congratulation!",
                text: msg,
                icon: status,
            });
        }else
        {
            Swal.fire({
                title: "Error!",
                text: msg,
                icon: "error",
            });
        }
        navigate("/Dashboard");
    //    if (check && check.payload && check.payload.data.data) {
    //                let newTotal = total - check.payload.data.data;
    //                setTotal(newTotal);
    //                Swal.fire({
    //                    title: "Congratulation!",
    //                    text: check.payload.data.message,
    //                    icon: "success",
    //                });
    //            } else {
    //                Swal.fire({
    //                    title: "Error!",
    //                    text: check.payload.data.message,
    //                    icon: "error",
    //                });
    //            }
       debugger
    }, []);

    

   

    

    
   
    

    return (
        <>
           
           
        </>
    );
}
