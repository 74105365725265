import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GoglLogin, RegisterUser, SendOtp, clearStatus } from "../../../Reducer/authSlice";
import PrivacyPolicy from "../PrivacyPolicy";
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const schema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    phone: Yup.string().min(10, "Phone number must be minimum 10 digits").max(12, "Phone must be less than 12 digits").required("Phone number is required"),
    mobile_otp: Yup.string().required("Mobile otp is required"),
    email_otp: Yup.string().required("Email otp is required"),
    password: Yup
        .string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .max(10, 'Password must be at most 10 characters'),
    confirmPassword: Yup
        .string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    checkboxx: Yup.boolean().oneOf([true], 'You must agree to the Privacy Policy').required('You must agree to the Privacy Policy'),
});

export default function Signup() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [emailll, setEmail] = useState('');
    const [phoneee, setPhoneee] = useState('');
    const [otp1, setotp1] = useState();
    const [otp2, setotp2] = useState();
    const [id, setId] = useState("");
    const success = useSelector(state => state.auth.success);
    const error = useSelector(state => state.auth.error);
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [isOtpButtonDisabled, setOtpButtonDisabled] = useState(false); // State to control button disabling
    const [isOtpButtonDisabled2, setOtpButtonDisabled2] = useState(false); // State to control button disabling


    useEffect(() => {
        if (success && success.status === 200) {
            
            if (success.data.type && success.data.type) {
                setId(success.data.data);
                Swal.fire({
                    title: 'Congratulation!',
                    text: success.data.message,
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Congratulation!',
                    text: success.data.message,
                    icon: 'success'
                });
                dispatch(clearStatus());
                const userData = success.data.data;
        
                // Check if user has a mobile (assuming it is checked by `userData.mobile`)
                if (!userData.mobile) {
                  // Navigate to a page to add the mobile number
                  navigate("/add-mobile-number");
                }else
                {
                    setTimeout(() => {
                        navigate('/Login');
                    }, 2000);
                }
                
            }
        }
    }, [success]);

    const onSubmit = async (datas) => {
        const { mobile_otp, email_otp } = datas;

        let formData = new FormData();
        formData.append('email', datas.email);
        formData.append('mobile', datas.phone);
        formData.append('mobile_otp', datas.mobile_otp);
        formData.append('email_otp', datas.email_otp);
        formData.append('password', datas.password);
        formData.append('id', id);
        formData.append('userType', 'User');
        dispatch(RegisterUser(formData));
    };

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
        }
    }

    const onError = (errors) => {
        console.log(errors);
    };

    async function sendOtp(type) {

        if (type == 'phone') {
            if (!phoneee) {
                alert("Please fill in the mobile number");
            } else if (!/^\d{10,12}$/.test(phoneee)) {
                alert("Please enter a valid mobile number");
            } else {
                let data = { type: type, data: phoneee, id: id };
                dispatch(SendOtp(data));
                disableOtpButton();
            }
        }
        if (type == 'email') {

            if (!emailll) {
                alert("Please fill in the email address");
            } else if (!/\S+@\S+\.\S+/.test(emailll)) {
                alert("Please enter a valid email address");
            } else {
                let data = { type: type, data: emailll, id: id };
                dispatch(SendOtp(data));
                disableOtpButton2();
            }
        }


    }


    const disableOtpButton = () => {
        setOtpButtonDisabled(true);
        setTimeout(() => {
            setOtpButtonDisabled(false);
        }, 30000); // 30 seconds
    };
    const disableOtpButton2 = () => {
        setOtpButtonDisabled2(true);
        setTimeout(() => {
            setOtpButtonDisabled2(false);
        }, 30000); // 30 seconds
    };

    const handleEmailChange = (e) => {
        if (e.target.name == "email") {
            setEmail(e.target.value);
            setotp2(''); // Clear email OTP value when email is changed
        } else {
            setPhoneee(e.target.value);
            setotp1('');
        }

    };

    const openPrivacyPolicy = () => {
        setOpenModal(true); // Open the modal
    }

    const responseGoogle = (response) => {
        console.log(response);
        // Handle the response from Google here
    };



    const handleSuccess = async(credentialResponse) => {
        console.log('response form google ', credentialResponse);
        const credential = credentialResponse.credential;
        if (credential) {
            const decoded = jwtDecode(credential);
            console.log('Success decoded:', decoded);
            let formData = new FormData();
            formData.append('email', decoded.email);
            formData.append('userType', "User");
            formData.append('name', decoded.name);
            formData.append('socialId', decoded.sub);
            formData.append('socialtype', "Google");
            const response=await dispatch(GoglLogin(formData));
            
            if (response?.payload?.data?.status === "success") {
                const userData = response?.payload?.data.data;
        
                // Check if user has a mobile (assuming it is checked by `userData.mobile`)
                if (!userData.mobile) {
                  // Navigate to a page to add the mobile number
                  navigate("/add-mobile-number");
                }
            }
        }

    };

    const handleError = (error) => {
        console.log('Error:', error);
    };

    return (
        <>
            <section id="login_sec" class="sign_up">
                <div class="container-fluid p-0 h-100">
                    <div class="row h-100">

                        <div class="col-lg-5 col-md-5 order pe-md-0">
                            <Link to="/"><i class="bi bi-arrow-left"></i> Back to Home</Link>
                            <div class="box_one">
                                <div class="login-img h-100">
                                    <img className="w-100 h-100" src="/images/loginnew.png" alt="" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-7 ps-md-0">
                            <div class="box-two h-100">
                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <div class="text-center">
                                        <Link to="/">  <img class="logo_login" src="images/Logo.png" alt="" /></Link>
                                    </div>
                                    <h2>Sign Up </h2>
                                    <p>Creating a New Account</p>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group mess">
                                                <label class="form-label">Phone Number</label>
                                                <input type="tel" class="form-control" {...register('phone')} onChange={handleEmailChange} />
                                                <i class="bi bi-telephone"></i>
                                                <span
                                                    // onClick={() => sendOtp('phone')}
                                                    onClick={() => !isOtpButtonDisabled && sendOtp('phone')}

                                                    style={{ cursor: isOtpButtonDisabled ? 'not-allowed' : 'pointer', opacity: isOtpButtonDisabled ? 0.5 : 1 }}
                                                >
                                                    {isOtpButtonDisabled ? 'Wait 30s...' : 'Send OTP'}
                                                </span>
                                                {errors.phone && <p className={"error__feedback"}>{errors.phone.message}</p>}

                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group mess">
                                                <label class="form-label">Email ID</label>
                                                <input type="email" class="form-control" {...register('email')} onChange={handleEmailChange} />
                                                <i class="bi bi-envelope"></i>

                                                <span
                                                    // onClick={() => sendOtp('email')}
                                                    onClick={() => !isOtpButtonDisabled2 && sendOtp('email')}

                                                    style={{ cursor: isOtpButtonDisabled2 ? 'not-allowed' : 'pointer', opacity: isOtpButtonDisabled2 ? 0.5 : 1 }}
                                                >
                                                    {isOtpButtonDisabled2 ? 'Wait 30s...' : 'Send OTP'}
                                                </span>

                                                {errors.email && <p className={"error__feedback"}>{errors.email.message}</p>}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Enter OTP sent on Mobile</label>
                                                <input type="text" class="form-control" name="mobile_otp"  {...register('mobile_otp')} />
                                                {errors.mobile_otp && <p className={"error__feedback"}>{errors.mobile_otp.message}</p>}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Enter OTP sent on Email</label>
                                                <input type="text" class="form-control"  {...register('email_otp')} />
                                                {errors.email_otp && <p className={"error__feedback"}>{errors.email_otp.message}</p>}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Create Password</label>
                                                <input type={showPassword ? "text" : "password"} class="form-control" name="password" {...register('password')} />

                                                <i
                                                    className={`bi bi-eye${showPassword ? "" : "-slash"}`}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                ></i>
                                                {errors.password && <p className={"error__feedback"}>{errors.password.message}</p>}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Confirm Password</label>
                                                <input type={showPassword2 ? "text" : "password"} class="form-control" name="confirmPassword" {...register('confirmPassword')} />
                                                <i
                                                    className={`bi bi-eye${showPassword2 ? "" : "-slash"}`}
                                                    onClick={() => setShowPassword2(!showPassword2)}
                                                ></i>
                                                {errors.confirmPassword && <p className={"error__feedback"}>{errors.confirmPassword.message}</p>}
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <h3>Please read and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                                                    conditions</a>
                                            </h3>
                                            <div class="form-check mb-2">

                                                <input class="form-check-input" type="checkbox" value="true" id="flexCheckDefault" {...register('checkboxx')} />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                I confirm that I have read and accepted the terms and conditions
                                                </label>
                                                {errors.checkboxx && <p className={"error__feedback"}>{errors.checkboxx.message}</p>}
                                            </div>

                                            {/* <h3>Please read <a onClick={() => openPrivacyPolicy()}>Privacy
                                                Policy</a> and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                                                    conditions</a>
                                            </h3> */}
                                            {/* <h3>Please read <a data-bs-toggle="modal" data-bs-target="#exampleModal">Privacy
                                                Policy</a> and agree to the terms and
                                                conditions
                                            </h3> */}
                                            <div class="sign_up_width">


                                                <div class="text-center">
                                                    <button type="submit">Signup</button>
                                                </div>

                                                <h5><span>or</span></h5>

                                                <ul>
                                                    <GoogleOAuthProvider clientId="405738133784-u8kl23jo5qgumpl7fef6gj8ms3l2k4u2.apps.googleusercontent.com">
                                                        {/* <li><a href="#"><img src="images/google-icon.png" alt="" /></a></li> */}
                                                        <li><a>
                                                            <GoogleLogin
                                                                onSuccess={handleSuccess}
                                                                onError={handleError}
                                                            />
                                                        </a></li>
                                                    </GoogleOAuthProvider>

                                                    <li><a href="#"><img src="images/micro-icon.png" alt="" /></a></li>
                                                    <li><a href="#"><img src="images/facebook-icon.png" alt="" /></a></li>
                                                </ul>

                                                <h6>Already have an account? <Link to="/Login">Login</Link></h6>

                                            </div>
                                        </div>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {openModal &&
                <PrivacyPolicy setOpenModal={setOpenModal} />
            }

        </>


    )

}

<div class="modal fade terms_conditions" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Terms and Conditions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div class="terms_cond_box">
                    <h3>1. Introduction Welcome</h3>
                    <p>Welcome to [Your Vedic Marriage Service] ("we," "us," "our"). We respect your privacy and are
                        committed to protecting your personal information. This Privacy Policy is designed to help
                        you understand how we collect, use, disclose, and safeguard your personal information. By
                        using our services, you consent to the practices described in this Privacy Policy.</p>

                    <h3>2. Information We Collect</h3>
                    <ul>
                        <li>Personal Information: We may collect personal information such as your name, contact
                            information, and other identifying details when you use our services or communicate with
                            us.</li>
                        <li>Marriage Details: We may collect information related to your marriage or marital
                            counseling, including preferences, schedules, and notes from counseling sessions.</li>
                        <li>Communication: We may collect information from your communications with us, including
                            emails, messages, and feedback.</li>
                    </ul>

                    <h3>3. How We Use Your Information</h3>
                    <p>We may use your information for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our marriage counseling services.</li>
                        <li>To communicate with you and respond to your inquiries.</li>
                        <li>To improve and personalize our services.</li>
                        <li>To send you relevant updates and information about our services.</li>
                    </ul>

                    <h3>4. Sharing Your Information</h3>
                </div>

            </div>
            <div class="modal-footer">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                        I agree to Terms and condition
                    </label>
                </div>
                <button type="submit" class="btn">Submit</button>
            </div>
        </div>
    </div>
</div>