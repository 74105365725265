import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Banner from './Banner';
import Form from './Form';
import HomeTabs from "./HomeTabs";
import Slider1Animation from "./Slider1/index1";
import Slider2Animation from "./Slider2";
import Slider3Animation from "./Slider3";

import SuccessStories from "../Tools/SuccessStrories";
import Resources from "../Tools/Resources";
import Plans from "./Plans";
import { useDispatch, useSelector } from 'react-redux';
import { homePage } from "../../../Reducer/apiSlice";
import { clearFilter } from "../../../Reducer/UserDashboardSlices/filterSlice";


export default function Home() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.api.data)
    useEffect(() => {
        dispatch(homePage());
        dispatch(clearFilter())
    }, []);

    return (
        <>
            <Header />
            <Banner data={data && data.BannerSection} slider={data && data.Slider} />
            {auth == null &&
                <Form />
            }



            {/* Insert the slider2 - Horizontal icon scrolelr */}
            <div
                style={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
                maxHeight: "700px", // Adjust height as needed
                }}
            >
                <Slider2Animation />
            </div>

            {/* Insert the slider1 -vertical scroller  */}
            <div
                style={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
                maxHeight: "700px", // Adjust height as needed
                }}
            >
                <Slider1Animation />
            </div>

            
            <SuccessStories data={data && data.stories} />
            <Resources data={data && data.Resources} />
            <Plans />
            <Footer />

        </>
    );
}
