import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserRegistration, SendOtpUser, Loginnn, SendForgetPasswordEmail,
     ResetPwdSubmit, VerifyForgetPwdOtp, GoogleLogin,UpdateTermapi,AddMobileNumberdata } from './api';
import { MatchMakerRegistration } from './makerApi';

export const RegisterUser = createAsyncThunk('userRegister', async (formData, { rejectWithValue }) => {
    try {
        const response = await UserRegistration(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const AddMobileNumber = createAsyncThunk('addmobilenumber', async (formData, { rejectWithValue }) => {
    try {
        const response = await AddMobileNumberdata(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const UpdateTerm = createAsyncThunk('UpdateTerm', async (formData, { rejectWithValue }) => {
    try {
        
        const response = await UpdateTermapi(formData);
        localStorage.setItem('user', JSON.stringify(response.data.data));
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



export const RegisterMatchMaker = createAsyncThunk('RegisterMatchMaker', async (formData, { rejectWithValue }) => {
    try {
        const response = await MatchMakerRegistration(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const SendOtp = createAsyncThunk('SendOtp', async (formData, { rejectWithValue }) => {
    try {
        const response = await SendOtpUser(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const Loginn = createAsyncThunk('loginnn', async (formData, { rejectWithValue }) => {
    try {
        const response = await Loginnn(formData);
        localStorage.setItem('user', JSON.stringify(response.data.data));
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const SendEmail = createAsyncThunk('SendEmail', async (formData, { rejectWithValue }) => {
    try {
        const response = await SendForgetPasswordEmail(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const verifyOtp = createAsyncThunk('verifyOtp', async (formData, { rejectWithValue }) => {
    try {
        const response = await VerifyForgetPwdOtp(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const ResetPwd = createAsyncThunk('ResetPwd', async (formData, { rejectWithValue }) => {
    try {
        const response = await ResetPwdSubmit(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


export const GoglLogin = createAsyncThunk('google loginn', async (formData, { rejectWithValue }) => {
    try {
        const response = await GoogleLogin(formData);
        localStorage.setItem('user', JSON.stringify(response.data.data));
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



const authSlice = createSlice({
    name: 'auth',
    initialState: {
        error: null,
        success: false,
        auth: null,
        token: "",
        data: null,
        successM: false,
        errorM: null,
        email: false,
        otp: null,
        // optVerified : false,
        Pwd: false
    },
    reducers: {
        clearStatus: state => {
            state.success = false;
            state.error = null;
            state.errorM = null;
            state.successM = false;

        },

        setUser: (state, action) => {
            state.auth = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        clearUser: state => {
            state.auth = null;
            state.success = false;
            localStorage.removeItem('user');
        },
        clearToken: state => {
            state.token = null;
            state.success = false;
            localStorage.removeItem('token');
        },

        saveFormLogin: (state, action) => {
            state.loginFields = action.payload;
        },
        clearOtp: state => {
            state.otp = null
        },
        clerForgetDta: state => {
            state.email = false;
            state.Pwd = false;
        }
    },
    extraReducers: builder => {
        builder
        .addCase(UpdateTerm.pending, state => {
            state.error = null;
            state.success = false;
        })
        .addCase(UpdateTerm.fulfilled, (state, action) => {
            
            state.success = action.payload;
            state.auth=null;
            state.auth = action.payload.data.data;
            state.error = null;
        })
        .addCase(UpdateTerm.rejected, (state, action) => {
            state.error = action.payload;
            state.success = false;
        })
            .addCase(RegisterUser.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(RegisterUser.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(RegisterUser.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            }).addCase(AddMobileNumber.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(AddMobileNumber.fulfilled, (state, action) => {
                state.success = action.payload;
                // debugger
                if (state.auth) {
                    state.auth = action.payload.data.data;
                    // state.auth = {
                    //     ...state.auth,  // Keep existing properties
                    //     mobile: action.payload.data.data.mobile, // Update mobile number
                    // };
            
                    // Optionally, sync to localStorage if needed
                    localStorage.setItem('user', JSON.stringify(state.auth));
                }
                state.error = null;
            })
            .addCase(AddMobileNumber.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(RegisterMatchMaker.pending, state => {
                state.errorM = null;
                state.successM = false;
            })
            .addCase(RegisterMatchMaker.fulfilled, (state, action) => {
                state.successM = action.payload;
                state.errorM = null
            })
            .addCase(RegisterMatchMaker.rejected, (state, action) => {
                state.successM = false;
                state.errorM = action.payload
            })
            .addCase(SendOtp.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(SendOtp.fulfilled, (state, action) => {
                state.otp = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SendOtp.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(Loginn.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(Loginn.fulfilled, (state, action) => {
                state.success = action.payload;
                state.auth = action.payload.data.data;
                state.error = null;
            })
            .addCase(Loginn.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GoglLogin.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GoglLogin.fulfilled, (state, action) => {
                state.success = action.payload;
                state.auth = action.payload.data.data;
                state.error = null;
            })
            .addCase(GoglLogin.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })

            .addCase(SendEmail.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(SendEmail.fulfilled, (state, action) => {
                state.email = true;
                // state.otp = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SendEmail.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            // .addCase(verifyOtp.pending, state => {
            //     state.error = null;
            //     state.success = false;
            // })
            // .addCase(verifyOtp.fulfilled, (state, action) => {
            //     state.optVerified = true;
            //     // state.otp = action.payload.data.data;
            //     state.success = action.payload;
            //     state.error = null;
            // })
            // .addCase(verifyOtp.rejected, (state, action) => {
            //     state.error = action.payload;
            //     state.success = false;
            // })

            .addCase(ResetPwd.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(ResetPwd.fulfilled, (state, action) => {
                state.email = true;
                state.Pwd = true;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(ResetPwd.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })


    },

});
export const { setUser, setToken, clearStatus, clearUser, clearToken, clearOtp, clerForgetDta } = authSlice.actions;
export default authSlice.reducer;

