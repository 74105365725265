import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ForgetPwd from "./ForgetPwd";
import ResetPwd from "./ResetPwdModal";
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import firebaseConfig from '../../../Firebase';
import { GoglLogin, Loginn, clearStatus } from "../../../Reducer/authSlice";
const schema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    password: Yup
        .string()
        .required('Password is required')
});


export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [showPassword2, setShowPassword2] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [emailForget, setEmailForget] = useState("");
    const success = useSelector(state => state.auth.success);
    const error = useSelector(state => state.auth.error);
    const emailSent = useSelector(state => state.auth.email);
    const auth = useSelector(state => state.auth.auth);


    useEffect(() => {
        if (auth) {
            setUserStatus();
        }

    }, [auth]);

    const onSubmit = async (datas) => {
        let formData = new FormData();
        formData.append('email', datas.email);
        formData.append('password', datas.password);
        dispatch(Loginn(formData));
    }


    // Function to set user's online status
    const setUserStatus = () => {
        const userStatusDatabaseRef = firebaseConfig.database().ref(`/Online_Users/${auth._id}`);

        const updateStatus = (status) => {
            userStatusDatabaseRef.update({
                status: status,
                timeStamp: Date.now()
            });
        };

        // Function to update status to online
        const setOnline = () => {
            updateStatus('online');
        };

        // Function to update status to offline
        const setOffline = () => {
            updateStatus('offline');
        };

        // Set the user's status to online on initial connection
        setOnline();

        // Handle browser tab/window close or browser disconnect
        window.addEventListener('beforeunload', () => {
            setOffline();
        });

        // Listen for connection state changes
        firebaseConfig.database().ref('.info/connected').on('value', (snapshot) => {
            const isConnected = snapshot.val();
            if (isConnected === false) {
                setOffline();
                return;
            }

            // Set the user's status to online on reconnection
            setOnline();
        });
    };



    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });

                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());

        }
    }
    if (success && success.status === 200) {
        if (emailSent === true) {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearStatus());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearStatus());
            setTimeout(() => {
                if (success.data.data) {
                    console.log('login data', success.data.data);
                    if (success.data.data.userType === "User") {
                        navigate('/UserDashboard');
                    } else {
                        navigate('/MakerDashboard');
                    }
                }

            }, 2000);
        }

    }


    const onError = (errors) => {
        console.log(errors);
    };


    const openModal = () => {
        setOpen1(true);

    }


    const handleSuccess =async (credentialResponse) => {
        console.log('response form google ', credentialResponse);
        const credential = credentialResponse.credential;
        if (credential) {
            const decoded = jwtDecode(credential);
            console.log('Success decoded:', decoded);
            let formData = new FormData();
            formData.append('email', decoded.email);
            formData.append('userType', "User");
            formData.append('name', decoded.name);
            formData.append('socialId', decoded.sub);
            formData.append('socialtype', "Google");
            
            const response = await dispatch(GoglLogin(formData));
            if (response?.payload?.data?.status === "success") {
                const userData = response?.payload?.data.data;
        
                // Check if user has a mobile (assuming it is checked by `userData.mobile`)
                if (!userData.mobile) {
                  // Navigate to a page to add the mobile number
                  navigate("/add-mobile-number");
                }
            }
           
        }

    };

    const handleError = (error) => {
        console.log('Error:', error);
    };


    return (
        <>
            <section id="login_sec">
                <div class="container-fluid p-0 h-100">
                    <div class="row h-100">
                        <div class="col-lg-5 col-md-5 order pe-md-0" >
                            <Link to="/">
                                <i class="bi bi-arrow-left"></i> Back to Home</Link>
                            <div class="box_one" >
                                <div class="login-img h-100">
                                    <img className="w-100 h-100" src="/images/loginnew.png" alt="" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-7 ps-md-0">
                            <div class="box-two h-100">
                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <div class="text-center">
                                        <Link to="/"><img class="logo_login" src="/images/Logo.png" alt="" /></Link>
                                    </div>
                                    <h2>Login</h2>
                                    <p>Welcome to Vedic Marriage</p>
                                    <div class="form-group">
                                        <label class="form-label">Email ID</label>
                                        <input type="email" class="form-control" name="email" {...register('email')} />
                                        {errors.email && <p className={"error__feedback"}>{errors.email.message}</p>}
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">Password</label>
                                        <input type={showPassword2 ? "text" : "password"} class="form-control" name="password" {...register('password')} />
                                        <i className={`bi bi-eye${showPassword2 ? "" : "-slash"}`}
                                            onClick={() => setShowPassword2(!showPassword2)}></i>
                                        {errors.password && <p className={"error__feedback"}>{errors.password.message}</p>}

                                    </div>
                                    <div className="forget_para" onClick={() => openModal()}>Forget Password?</div>

                                    <button type="submit">Login</button>

                                    <h5><span>or</span></h5>
                                    <ul>
                                        <GoogleOAuthProvider clientId="405738133784-u8kl23jo5qgumpl7fef6gj8ms3l2k4u2.apps.googleusercontent.com">
                                            {/* <li><a href="#"><img src="images/google-icon.png" alt="" /></a></li> */}
                                            <li><a>
                                                <GoogleLogin
                                                    onSuccess={handleSuccess}
                                                    onError={handleError}
                                                />
                                            </a></li>
                                        </GoogleOAuthProvider>
                                        <li><a href="#"><img src="images/micro-icon.png" alt="" /></a></li>
                                        <li><a href="#"><img src="images/facebook-icon.png" alt="" /></a></li>
                                    </ul>

                                    <h6>Don’t have an account ? <Link to="/Signup">Sign Up</Link></h6>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {open1 === true &&
                <ForgetPwd setOpen1={setOpen1} setOpen2={setOpen2} setEmailForget={setEmailForget} />

            }
            {open2 === true &&
                <ResetPwd setOpen2={setOpen2} emailForget={emailForget}
                />

            }





        </>
    )

}